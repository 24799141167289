import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import axios from 'axios'
import call from '../../assets/images/call.png'
import web from '../../assets/images/web.png'
import moment from 'moment'
import transition from '../../assets/images/transition.png'
import event from '../../assets/images/event.png'
import message from '../../assets/images/ai-message.png'
import email from '../../assets/images/ai-email.png'

export default function LeadOverView() {
  const [values, setValues] = useState({})
  const [counter, setCounter] = useState({})
const {startDateFilter,endDateFilter}=useState()

  useEffect(() => {

      fetchLeadCounter()
      handleGetLeadData()


   
  }, [])
  const handleGetLeadData = (date1) => {
    const today=moment().subtract(7,"d")
    var url = `${
      process.env.REACT_APP_BASE_APP_URL
    }/api/v1/leads/call-tracking-analytics`
    axios
      .get(url)
      .then(res => {
        setValues(res?.data?.data)
      })
      .catch(error => {})
  }


  const fetchLeadCounter = async (date1) => {
    const today=moment().subtract(1,"d")
    const token = await localStorage.getItem('access_token')
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/leads-counters?date_from=${moment(date1?.startDateFilter)?.format('YYYY/MM/DD')}&date_to=${moment(date1?.endDateFilter)?.format(
      'YYYY/MM/DD'
    )}`
    const settings = {
      url: URL,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    }
    axios(settings)
      .then(res => {
       setCounter( res?.data?.data || {})
        })
      
      .catch(err => {
        if (err?.response?.status === 401) {
          return
        }
        if (!err?.response) {
        }
      })
  }
  return (
    <div className="cardbox-wrapper deatil_box">
      <div className="container-trial">
        <Row className="banner-description-wrapper planinfo-box justify-content-start align-items-stretch">
          <Col md={4}>
            <div className="banner-description-box h-100">
              <h5 className="mb-3" style={{fontSize:'17.5px'}}>Leads by Type</h5>
              <ul className="lead-overview">
                <li>
                  <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#b4a7eb' }}
                  ></span>
                  Calls
                  </div> 
                  
                  
                  <div className="leads-count">{values?.calls || 0}</div>
                </li>
                <li>
                <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#81d8af' }}
                  ></span>
                  Form  
                  </div> <div className="leads-count">{values?.web_forms || 0}</div>
                </li>
                <li>
                <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#feba8a' }}
                  ></span>
                  Chats 
                  </div>  <div className="leads-count">{values?.chats || 0}</div>
                </li>
                <li>
                <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#87bdf5' }}
                  ></span>
                  Callback{' '}
                  </div><div className="leads-count">{values?.callbacks || 0}</div>
                </li>
                <li>
                <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#e9a5ea' }}
                  ></span>
                  Webhook{' '}
                  </div> <div className="leads-count">{values?.webhooks || 0}</div>
                </li>
                <li>
                <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#8adadd' }}
                  ></span>
                  Messages{' '}
                  </div> <div className="leads-count">{values?.messages || 0}</div>
                </li>
                {/* <li>
                  <span
                    className="status"
                    style={{ backgroundColor: '#ffd68c' }}
                  ></span>
                  Emails   <div className="leads-count">{values?.emails || 0}</span>
                </li> */}
              </ul>
            </div>
          </Col>
          <Col md={4}>
            <div className="banner-description-box h-100">
              <h5 className="mb-3" style={{fontSize:'17.5px'}}>Lead Qualification</h5>
              <ul className="lead-overview">
                <li>
                  <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#85d49a' }}
                  ></span>
                  Qualified
                  </div>
                  {/* <div className="leads-count">
                 {values?.qualified_rate || 0}%
                  </div> */}
                  <div className="leads-count">{`${values?.qualified_leads || 0} (${values?.qualified_rate || 0}%)`} </div>

                </li>
            
                <li>
                <div>

                  <span
                    className="status"
                    style={{ backgroundColor: '#ffca7b' }}
                  ></span>

                  Pending
                  </div>
                  <div className="leads-count">{`${values?.pending_leads|| 0} (${values?.pending_rate || 0}%)`} </div>
                </li>
                <li>
                  <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#f98895' }}
                  ></span>
                  Not Qualified
                  </div>
                  {/* <div className="leads-count">{values?.not_qualified_rate || 0}% */}
                  <div className="leads-count">{`${values?.not_qualified_leads|| 0} (${values?.not_qualified_rate || 0}%)`} </div>


                </li>
                <li>
                  <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#b7c3c8' }}
                  ></span>

                  Spam
                  </div>
                  {/* <div className="leads-count">{values?.spam_rate || 0}% */}
                  <div className="leads-count">{`${values?.spam|| 0} (${values?.spam_rate || 0}%)`} </div>


                </li>
                <li>
                <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#87bdf5' }}
                  ></span>


                  Assigned
                  </div>
                  {/* <div className="leads-count">{values?.assigned_rate || 0}%
                  </div> */}
                  <div className="leads-count">{`${values?.assigned_leads|| 0} (${values?.assigned_rate || 0}%)`} </div>

                </li>
                <li>
                <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#e9a5ea' }}
                  ></span>
              Closed 
                  </div>
                  {/* <div className="leads-count">{values?.closed_rate || 0}%
                  </div> */}
                  <div className="leads-count">{`${values?.closed_leads|| 0} (${values?.closed_rate || 0}%)`} </div>
                
                </li>
                <li>
                <div>
                  <span
                    className="status"
                    style={{ backgroundColor: '#e9a5ea' }}
                  ></span>
              Total 
                  </div>
                  {/* <div className="leads-count">{values?.all_leads || 0}
                  </div> */}
                  <div className="leads-count">{`${values?.all_leads|| 0}`} </div>

                </li>
              </ul>
            </div>
          </Col>
          <Col md={4}>
            {/* <div className="banner-description-box h-100">
              <h5 className="mb-3">
                Which account are converting better?
              </h5>
              <ReactApexChart
                options={accounting.options}
                series={accounting.series}
                type="bar"
                height={350}
              />
            </div> */}

            <div className="banner-description-box h-100">
              <h5 className="mb-3" style={{fontSize:'17.5px'}}>
             Today's Call Stats
              </h5>
              <ul className="lead-overview">
                <li>
                  <div className="lead-overview-title">
                    <img src={call} alt="" /> Missed calls
                  </div>
                  <div className="leads-count">
                    {counter?.calls?.missed_calls || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={web} alt="" /> Answered Calls
                  </div>
                  <div className="leads-count">
                    {counter?.calls?.answered_calls || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                {/* <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={chat} alt="" /> Chats
                  </div>
                  <div className="leads-count">{values?.chats || 0} Calls</div>
                  <div className="lead-btn">
                    <button>View Calls</button>
                  </div>
                </li> */}
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={transition} alt="" />Voicemail
                  </div>
                  <div className="leads-count">
                    {counter?.calls?.voicemail || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={event} alt="" /> Outgoing calls
                  </div>
                  <div className="leads-count">
                    {counter?.outgoing_calls?.all_calls || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={message} alt="" /> Scheduled Calls
                  </div>
                  <div className="leads-count">
                    {counter?.schedule_calls?.all_calls  || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={email} alt="" /> All calls
                  </div>
                  <div className="leads-count">{counter?.calls?.all_calls || 0} Calls</div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
              </ul>
            </div>
          </Col>   
         
        </Row>
      </div>
    </div>
  )
}
