import React from "react"
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import ErrorIcon from '@mui/icons-material/Error'
import ChatIcon from '@mui/icons-material/Chat'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

export const compareNotes = (a, b) => {
  const d1 = new Date(a.created_at)
  const d2 = new Date(b.created_at)
  return d1 - d2
}

export const getMessageClass = participant => {
  if (!participant) return 'message-sms'
  const type = participant[0].channel[0]
  switch (type) {
    case 'sms':
      return 'message-sms'
    case 'whatsapp':
      return 'message-whatsapp'
    case 'webchat':
      return 'message-webchat'
    default:
      return 'message-sms'
  }
}

export const getMessageStatus = status => {
  switch (status) {
    case 'read':
      return <DoneAllIcon fontSize="14" color="primary" />
    case 'pending':
      return <DoneIcon />
    case 'failed':
      return <ErrorIcon fontSize="14" color="error" />
    default:
      return <DoneIcon fontSize="14" />
  }
}

export const getMessageSymbole = participant => {
  if (!participant) return <MailOutlineIcon />
  const type = participant[0].channel[0]
  switch (type) {
    case 'sms':
      return <MailOutlineIcon />
    case 'whatsapp':
      return <WhatsAppIcon />
    case 'webchat':
      return <ChatIcon />
    default:
      return <MailOutlineIcon />
  }
}

export const isToday = date => {
  const today = new Date()

  if (today.toDateString() === date.toDateString()) {
    return true
  }

  return false
}
