import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Dimmer, Dropdown, Input, Loader, Modal, Table, TextArea } from 'semantic-ui-react'
import CommonButtons from '../common/CommonButtons'
import Axios from 'axios'
import { CommonNotify } from '../common/CommonNotify'
import { Col, Row } from 'react-bootstrap'
import { Tooltip } from '@mui/material'

const intitialValue = {
  type: 'message',
  target: 'Conversation',
  title: '',
  description: ''
}
const TeamplateCampaign = () => {
    const [templateList, setTemplateList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [showTemplate, setShowTemplate] = useState(false)
      const [availableTemplateList, setAvailableTemplateList] = useState([])
      const [availableTemplateListAll, setAvailableTemplateListAll] = useState(
        []
      )
      const [loading, setLoading] = useState(false)
      const [newTemplates, setNewTemplates] = useState(false)
      const [selectedTemplated, setSelectedTemplated] = useState(null)
      const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
      const [searchValue, setSarchValue] = useState(null)
      const [personalise, setPersonalise] = useState(false)
  
//    useEffect(() => {
//      if (searchValue) {
//        filterRecord()
//      } else {
//        setAvailableTemplateList(availableTemplateListAll || [])
//        setSelectedTemplated(
//          availableTemplateListAll?.[0] && availableTemplateListAll?.[0]
//        )
//      }
//    }, [searchValue])
   
     const handleDeleteTemplate = async () => {
       setOpenConfirmationModal(false)
       if (selectedTemplated?.id) {
         setLoading(true)
         const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-response/${selectedTemplated?.id}`
         await Axios.delete(url)
           .then(res => {
           getTemplates()
             CommonNotify('Deleted successfully')
           })
           .catch(err => {
             setLoading(false)
             CommonNotify(
               err?.response?.data?.errors[0] || 'Somethign went to wron!1'
             )
           })
       } else {
         CommonNotify('Please select any template first')
       }
     }
   
  useEffect(() => {
    if (newTemplates) {
      setSelectedTemplated({ ...intitialValue })
    }
  }, [newTemplates])
  
    // const filterRecord = () => {
    //   const filterValue = availableTemplateListAll?.filter(
    //     ({ title }) =>
    //       title &&
    //       title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    //   )
    //   setSelectedTemplated(filterValue?.[0] && filterValue?.[0])
    //   setAvailableTemplateList(filterValue || [])
    // }
  const handleGetTemplate = async flag => {
    setLoading(true)
    let data = []
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Lead`
    await Axios.get(url)
      .then(res => {
        const filter = res?.data?.data?.map(list => {
          list.target = 'Lead'
          return list
        })
        data = [...data, ...filter]
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors[0] || 'Somethign went to wrong!2'
        )
      })
    url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Conversation`
    await Axios.get(url)
      .then(res => {
        const filter = res?.data?.data?.map(list => {
          list.target = 'Conversation'
          return list
        })
        data = [...data, ...filter]
    
        setAvailableTemplateList(data || [])
        setAvailableTemplateListAll(data || [])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors[0] || 'Somethign went to wrong!3'
        )
      })
  }
  
    const handleSelectTemplates = list => {
      setSelectedTemplated(list)
      setNewTemplates(false)
    }

    const handleSearch = (e, data) => {
      setSarchValue(e?.target?.value)
    }

    const handleChange = (name, value) => {
      setSelectedTemplated({
        ...selectedTemplated,
        [name]: value
      })
    }

  const handleChangeDropdown = async ({ value }) => {
    handleChange('target', value)
    return
  }

  const handlePersonalise = () => {
    setPersonalise(!personalise)
  }

  const handleAppendMessage = value => {
    setSelectedTemplated({
      ...selectedTemplated,
      ['description']: `${
        selectedTemplated?.description ? selectedTemplated?.description : ''
      } ${value}`
    })
  }
  
      const getTemplates = async id => {
        setIsLoading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Conversation`
        await Axios
          .get(url)
          .then(res => {
            setIsLoading(false)
            if (res?.data?.data && res?.data?.data?.length) {
              let filterValue = []
           
              setTemplateList(res?.data?.data || [])
            } else {
              setTemplateList([])
            }
          })
          .catch(err => {
            setIsLoading(false)
            CommonNotify(
              err?.response?.data?.errors[0] || 'Somethign went to wrong!4'
            )
          })
      }
      
      
      useEffect(() => {
        getTemplates()
   
      }, [])
      
      
        const handleSaveTemplate = async () => {
          let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-response`,
            payload = {
              type: 'message',
              target: 'Conversation',
              title: '',
              description: ''
            }
          if (
            !selectedTemplated?.title ||
            // !selectedTemplated?.target ||
            !selectedTemplated?.description
          ) {
            CommonNotify('Please fill all filed, All field are required')
            return
          }
          setLoading(true)
          payload.title = selectedTemplated?.title
          payload.target = selectedTemplated?.target || 'Conversation'
          payload.description = selectedTemplated?.description
          if (selectedTemplated?.id) {
            url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-response/${selectedTemplated?.id}/update`
          }
          await Axios.post(url, payload)
            .then(res => {
              CommonNotify(
                selectedTemplated?.id
                  ? 'Updated successfully'
                  : 'Created successfully'
              )
              setShowTemplate(!showTemplate)
              setNewTemplates(false)
              setSelectedTemplated(null)
              getTemplates()
            })
            .catch(err => {
                console.log('err', err)
              setLoading(false)
              CommonNotify(
                err?.response?.data?.errors[0]  || 'dfwedwf'
              )
            })
        }
      
  return (
    <div
      className="history-campaign"
      style={{ maxWidth: '1200px', margin: '0 auto' }}
    >
      <Dimmer style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 className="mb-4">Templates</h3>
          <Button
            className="mb-4"
            onClick={() => setShowTemplate(!showTemplate)}
          >
            <i class="fas fa-plus"></i> Add Template
          </Button>
        </div>
        <div>
          <Table className="border-0">
            <Table.Header style={{ padding: '12px 15px' }}>
              <Table.Row>
                <Table.HeaderCell>Date Modified</Table.HeaderCell>
                <Table.HeaderCell>Time Modified</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Message</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {console.log('templateList', templateList)}
            <Table.Body style={{ padding: '10px 12px' }}>
              {templateList &&
                templateList?.length > 0 &&
                templateList?.map((list, index) => {
                  return (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {moment(list?.updated_at)?.format('DD-MM-YYYY')}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(list?.updated_at)?.format('LT')}
                      </Table.Cell>
                      <Table.Cell>{list?.title}</Table.Cell>
                      <Table.Cell>{list?.description}</Table.Cell>
                      <Table.Cell>
                        {
                          <div className="d-flex justify-space-between">
                            <Tooltip title="Edit Template">
                              <button>
                                <i
                                  class="fas fa-edit"
                                  style={{ paddingRight: '5px' }}
                                  onClick={() => {
                                    setSelectedTemplated(list)
                                    setShowTemplate(!showTemplate)
                                  }}
                                ></i>{' '}
                              </button>
                            </Tooltip>

                            <Tooltip title="Delete Template">
                              <button>
                                <i
                                  class="fas fa-trash"
                                  onClick={() => {
                                    setSelectedTemplated(list)
                                    setOpenConfirmationModal(true)
                                  }}
                                  style={{ color: 'red', paddingRight: '5px' }}
                                ></i>
                              </button>
                            </Tooltip>
                          </div>
                        }
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
          </Table>
        </div>
      </>

      {showTemplate && (
        <Modal
          style={{ maxWidth: '50%' }}
          className="subscription-modal"
          open={showTemplate}
          onClose={() => setShowTemplate(!showTemplate)}
        >
          <Modal.Header>
            {' '}
            <p className="cancel-title">Add Template</p>
            <i
              onClick={() => setShowTemplate(!showTemplate)}
              className="fa fa-times"
              aria-hidden="true"
            ></i>
          </Modal.Header>
          <Modal.Content scrolling>
            {' '}
            <div className="template-box-right-wrap">
              <div className="form-field">
                <label className="mr-2" style={{fontWeight:600}}>Template Name</label>
                <Input
                  className="width-input"
                  value={selectedTemplated?.title || ''}
                  onChange={e => {
                    handleChange('title', e?.target?.value)
                  }}
                />
              </div>
              {/* <div className="form-field">
                <label>Type</label>
                <div className="col-lg-7 px-0">
                  <Dropdown
                    placeholder={'Select group...'}
                    fluid
                    search
                    selection
                    name="target"
                    onChange={(e, result) => handleChangeDropdown(result)}
                    value={selectedTemplated?.target || ''}
                    options={[
                      { text: 'Lead', key: 1, value: 'Lead' },
                      { text: 'Conversation', key: 2, value: 'Conversation' }
                    ]}
                  />
                </div>
              </div> */}
              <div className="form-field">
                <label className="mt-3" style={{fontWeight:600}}>Template Message</label>
                <p>
                  Add variables to the message that get dynamically filled based
                  on the customer in the ticket and the support agent responding
                  to the ticket.
                </p>
                <div className="col-lg-12 px-0">
                  <Row>
                    <Col md={7} className="pr-0">
                      <TextArea
                        fluid
                        rows={10}
                        className="d-block"
                        value={selectedTemplated?.description}
                        onChange={e => {
                          handleChange('description', e?.target?.value)
                        }}
                      />
                      <p className="mt-2">
                        <b>
                          {(selectedTemplated?.description &&
                            selectedTemplated?.description?.length) ||
                            0}
                        </b>{' '}
                        characters used per message
                      </p>
                    </Col>
                    <Col md={3}>
                      <ul className="msgOpts">
                        <li
                          className={`${personalise ? 'show' : ''}`}
                          onClick={() => handlePersonalise()}
                        >
                          <span>Personalise</span>

                          <div className="personalise-tags">
                            <span onClick={() => handleAppendMessage(`{NAME}`)}>
                              Name
                            </span>
                            <span
                              onClick={() => handleAppendMessage(`{EMAIL}`)}
                            >
                              Email
                            </span>
                            <span
                              onClick={() =>
                                handleAppendMessage(`{COMPANY_NAME}`)
                              }
                            >
                              Company Name
                            </span>
                            <span
                              onClick={() =>
                                handleAppendMessage(`{MEETING_LINK}`)
                              }
                            >
                              Meeting Link
                            </span>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
              {/* <p>
              Image attachments in Fast reply templates are supported only on
              Whatsapp & Instagram DM channels. You can attach a maximum of 1
              image
            </p> */}
              <div className="button_container">
                <div>
                  <Button
                    className="btn save-button mr-3"
                    onClick={handleSaveTemplate}
                  >
                    Save Template
                  </Button>
                  {/* <Button
                  className="btn duplicate-button"
                  disabled={newTemplates}
                >
                  Duplicate Template
                </Button> */}
                </div>
              </div>
            </div>
          </Modal.Content>
        </Modal>
      )}
      <Modal
        className="local-number-modal"
        open={openConfirmationModal}
        onClose={e => setOpenConfirmationModal(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this template ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setOpenConfirmationModal(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={handleDeleteTemplate}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default TeamplateCampaign