import axios from 'axios'
import moment from 'moment'
import React, { Component } from 'react'
//redux
import { connect } from 'react-redux'
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react'
import {
    getData,
    getPersonalLink,
    getWidget
} from '../actions/home'
import {
    GET_DATA_LAST_MONTH,
    GET_DATA_LAST_WEEK,
    GET_DATA_THIS_MONTH,
    GET_DATA_THIS_WEEK,
    GET_DATA_TODAY,
    GET_DATA_YESTERDAY
} from '../actions/types'
import { logOut } from '../common/ProfileModal'
import Title from '../common/Title'
import CommonDateRangePicker from '../components/daterangepicker/CommonDateRangePicker'
import ActivityBox from '../components/home/ActivityBox'
import ActivityChart from '../components/home/ActivityChart'
import HomeContent from '../components/home/HomeContent'
import AnalyticsGraph from "./AnalyticsGraph"
import MeetingOverView from './MeetingOverView'

const options = [
  { key: 1, text: 'Today', value: 'Today' },
  { key: 2, text: 'Yesterday', value: 'Yesterday' },
  { key: 3, text: 'This week', value: 'This week' },
  { key: 4, text: 'Last week', value: 'Last week' },
  { key: 5, text: 'This month', value: 'This month' },
  { key: 6, text: 'Last month', value: 'Last month' },
  { key: 7, text: 'Custom range', value: 'Select custom' }
]

class Analytics extends Component {
  state = {
    subscriptionData: {
      tableContentData: [
        { ends: 0, max_calls: 0, calls_used: 0, max_sms: 0, sms_used: 0 }
      ]
    },
    isTrial: false,
    trialDays: 0,
    isLoading: false,
    phoneNumbers: null,
    showSubscriptionModal: false,
    pleaseUpgrade: '',
    plan_name: null,
    subscriptionModalVisibility: false,
    titleLine: '',
    loadTitle: false,
    upgradeLine: '',
    chartData: {},
    personalLink: '',
    titleContent: {
      titleOne: 'Welcome Back, ' + localStorage.getItem('first_name')
    },
    visitor: {
      title: 'calls',
      legend: false,
      stepSize: '5',
      yAxes: false,
      padding: {
        top: '20',
        right: '80',
        bottom: '20',
        left: '50'
      },
      gridLines: false,
      fontSize: 16,
      fontStyle: '400',
      labels: [
        '3rd April',
        '6th April',
        '7th April',
        '8th April',
        '9th April',
        '10th April',
        '11th April'
      ],
      datasets: [
        {
          label: 'All Calls',
          fill: false,
          backgroundColor: 'rgba(249,166,9,1)',
          borderColor: 'rgba(249,166,9,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(249,166,9,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(249,166,9,1)',
          pointHoverBorderColor: 'rgba(249,166,9,1)',
          pointHoverBorderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 0,
          data: [3, 4, 5, 6, 7, 8, 15],
          options: {
            legend: {
              diplay: false
            },
            tooltips: {
              enabled: false
            }
          }
        }
      ]
    },

    data: {
      title: 'all activities',
      legend: true,
      stepSize: '10',
      yAxes: true,
      padding: {
        top: '0',
        right: '0',
        bottom: '0',
        left: '0'
      },
      fontSize: 14,
      fontStyle: '600',
      gridLines: true,
      labels: [
        '',
        '23/03/2019',
        '23/04/2019',
        '23/05/2019',
        '23/06/2019',
        '23/07/2019',
        '23/08/2019',
        '23/10/2019'
      ],
      datasets: [
        {
          label: 'All Events',
          fill: false,
          backgroundColor: 'rgba(31,133,254,1)',
          borderColor: 'rgba(31,133,254,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'black',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(31,133,254,1)',
          pointHoverBorderColor: 'rgba(31,133,254,1)',
          pointHoverBorderWidth: 1,
          pointRadius: 2,
          pointHitRadius: 2,
          data: [0, 3, 4, 5, 6],
          options: {
            tooltips: {
              mode: 'point'
            }
          }
        },
        {
          label: 'All calls',
          fill: false,
          backgroundColor: 'rgba(41,128,2,1)',
          borderColor: 'rgba(41,128,2,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'black',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(41,128,2,1)',
          pointHoverBorderColor: 'rgba(41,128,2,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 2,
          data: [0, 1, 2, 3]
        },
        /* {
          label: 'All message',
          fill: false,
          backgroundColor: 'rgba(102,102,102,1)',
          borderColor: 'rgba(102,102,102,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'black',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(102,102,102,1)',
          pointHoverBorderColor: 'rgba(102,102,102,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 2,
          data: [0, 3, 4, 5, 6]
        }, */
        {
          label: 'Meetings Booked',
          fill: false,
          backgroundColor: 'rgba(59,89,153,1)',
          borderColor: 'rgba(59,89,153,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'black',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(59,89,153,1)',
          pointHoverBorderColor: 'rgba(59,89,153,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 2,
          data: [0, 3, 4, 5, 6]
        },
        {
          label: 'Successful Call',
          fill: false,
          backgroundColor: 'rgba(59,89,153,1)',
          borderColor: 'rgba(59,89,153,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'black',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(59,89,153,1)',
          pointHoverBorderColor: 'rgba(59,89,153,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 2,
          data: [0, 3, 4, 5, 6]
        },
        {
          label: 'Failed Call',
          fill: false,
          backgroundColor: 'rgba(59,89,153,1)',
          borderColor: 'rgba(59,89,153,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'black',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(59,89,153,1)',
          pointHoverBorderColor: 'rgba(59,89,153,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 2,
          data: [0, 3, 4, 5, 6]
        }
      ]
    },
    toplead: {
      type: 'topLead',
      title: 'Top Lead Generating Pages'
    },
    cardboxData: [
      {
        type: 'flatRate',
        title: 'Avg. Lead Response Time',
        callLog: '00',
        callSup: 's'
      },
      {
        type: 'flatRate',
        title: 'AGENT ANSWERRATE',
        callLog: '00%'
      },
      {
        type: 'flatRate',
        title: 'CUSTOMER ANSWER RATE',
        callLog: '00%'
      },
      {
        type: 'flatRate',
        title: 'Avg Call Duration',
        callLog: '00',
        callSup: 's'
      }
    ],
    leadStatusData: [
      {
        type: 'flatRate',
        title: 'PENDING',
        callLog: '00',
        callSup: 's'
      },
      {
        type: 'flatRate',
        title: 'ASSIGNED',
        callLog: '00%'
      },
      {
        type: 'flatRate',
        title: 'QUALIFIED',
        callLog: '00%'
      },
      {
        type: 'flatRate',
        title: 'CLOSED',
        callLog: '00',
        callSup: 's'
      }
    ],
    usage: {
      type: 'usage',
      title: 'Usage',
      usageLog: '0/0'
    },
    currentCall: {
      type: 'currentCalls',
      title: 'Current Calls'
    },
    dataTable: {
      type: '3',
      header: [
        {
          headerTitle: 'ID'
        },
        {
          headerTitle: 'Customer'
        },
        {
          headerTitle: 'Scheduled time'
        }
      ],
      tableContentData: [
        {
          id: '#456765',
          name: 'Alpha Team',
          type: 'Primary'
        }
      ]
    },
    filterTable: {
      startDateFilter: moment(),
      endDateFilter: moment()
    },
    activityBoxData: null,
    selectedPickerOption: 'Today',
    role_name: 'Admin'
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.todayData !== prevProps.todayData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.todayData.statData,
        chartData: this.props.todayData.dataChart
      })
    }

    if (this.props.personalLink !== prevProps.personalLink) {
      this.setState({
        personalLink: this.props.personalLink
      })
    }

    if (this.props.yesterdayData !== prevProps.yesterdayData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.yesterdayData.statData,
        chartData: this.props.yesterdayData.dataChart
      })
    }

    if (this.props.thisWeekData !== prevProps.thisWeekData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.thisWeekData.statData,
        chartData: this.props.thisWeekData.dataChart
      })
    }

    if (this.props.lastWeekData !== prevProps.lastWeekData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.lastWeekData.statData,
        chartData: this.props.lastWeekData.dataChart
      })
    }

    if (this.props.thisMonthData !== prevProps.thisMonthData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.thisMonthData.statData,
        chartData: this.props.thisMonthData.dataChart
      })
    }

    if (this.props.lastMonthData !== prevProps.lastMonthData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.lastMonthData.statData,
        chartData: this.props.lastMonthData.dataChart
      })
    }
    if(JSON.stringify(this.props)!==JSON.stringify(prevProps)){
      this.setState({
        isLoading: false,
   
      })
    }  
  }

  componentDidMount = async () => {
    // this.handleApply()
    document.title = 'Analytics'
    /* const quick_setup = await localStorage.getItem('quick_setup') */
    const role_name = await localStorage.getItem('role_name')
    /* const mobile_verification_status  = await localStorage.getItem('mobile_verification_status'); */
    this.setState({
        isLoading: true,
        loadTitle:true
    })
    let start_date = new Date(this.state.filterTable.startDateFilter)
    let end_date = new Date(this.state.filterTable.endDateFilter)
    start_date = moment(start_date)
    end_date = moment(end_date)
    start_date = start_date.format('DD/MM/YYYY')
    end_date = end_date.format('DD/MM/YYYY')

    start_date = this.formatData(start_date)
    end_date = this.formatData(end_date)

    if (this.props.todayData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.todayData.statData,
        chartData: this.props.todayData.dataChart
      })
    } else {
      this.props.getData(
        this.state.filterTable.startDateFilter,
        this.state.filterTable.endDateFilter,
        GET_DATA_TODAY
      )
    }

    if (this.props.personalLink) {
      this.setState({
        personalLink: this.props.personalLink
      })
    } else {
      // this.props.getPersonalLink()
    }

    const n = await JSON.parse(localStorage.getItem('phone_numbers'))
    await this.setState({ phoneNumbers: n, role_name: role_name })

    if (!this.props.widget) {
      // this.props.getWidget()
    }
  }

  handlePercentage = () => {
    this.setState({ percentage: this.state.percentage + 20 })
  }

  handleStartDate = date => {
    const { filterTable } = this.state
    this.setState(
      {
        filterTable: {
          startDateFilter: date,
          endDateFilter: filterTable.endDateFilter
        }
      },
      () => {}
    )
  }

  handleEndDate = date => {
    const { filterTable } = this.state

    this.setState(
      {
        filterTable: {
          startDateFilter: filterTable.startDateFilter,
          endDateFilter: date
        }
      },
      () => {}
    )
  }

  changeLoadingState = state => {
    this.setState({ isLoading: false })
  }
  formatData = date => {
    var arr = date.split('/')
    return arr[2] + '-' + arr[1] + '-' + arr[0]
  }

  fetchChartDataCutom = async (start_date, end_date) => {
    const apiToken = await localStorage.getItem('access_token')

    var s_date = moment(start_date)
    var e_date = moment(end_date)

    var days_difference = e_date.diff(s_date, 'days')
    var apiEndPoint = days_difference > 30 ? 'month' : 'day'

    // this.setState({
    //   isLoading: true
    // })

    var url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/chart/data/${start_date}/${end_date}/${apiEndPoint}`

    axios
      .get(url)
      .then(async res => {
        if (res.data.data) {
          const d = res.data.data
          const month = [
            'null',
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'Decemer'
          ]
          const labelArray = ['']
          const allEvents = [0]
          const allCalls = [0]
          const allMessage = [0]
          const ScheduleCall = [0]
          const successfullCalls = [0]
          const failedCalls = [0]

          if (d.length !== 0) {
            await d.map(function(item, index) {
              apiEndPoint === 'month'
                ? labelArray.push(month[item.label])
                : labelArray.push(item.label)
              allEvents.push(item.all_leads)
              allCalls.push(
                item.live_call + item.schedule_call + item.digital_calls
              )
              allMessage.push(item.leave_message)
              ScheduleCall.push(item.schedule_call)
              successfullCalls.push(item.success_call)
              failedCalls.push(item.failed_call)
            })
          }
          const data = {
            labelArray: labelArray,
            allEvents: allEvents,
            allCalls: allCalls,
            allMessage: allMessage,
            ScheduleCall: ScheduleCall,
            successfullCalls: successfullCalls,
            failedCalls: failedCalls
          }
          await this.setState({
            isLoading: false,
            chartData: data
          })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        if (error?.response?.status === 401) {
          logOut()
          return
        }
        if (!error?.response) {
        }
      })
  }

  fetchDataCustom = async (customStartDate, customEndDate) => {
    const apiToken = await localStorage.getItem('access_token')
    let start_date = customStartDate
    let end_date = customEndDate

    start_date = this.formatData(start_date)
    end_date = this.formatData(end_date)

    this.setState({
      isLoading: true
    })

    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/get-leads-in-custom-period/${start_date}/${end_date}`

    axios
      .get(url)
      .then(res => {
        if (res.data.data) {
          this.setState({
            isLoading: false,
            activityBoxData: res.data.data
          })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false })
        if (error?.response?.status === 401) {
          logOut()
          return
        }
        if (!error?.response) {
        }
      })
  }

  handleApply = (
    event,
    picker = {
      startDate: new Date(),
      endDate: new Date()
    }
  ) => {
    this.fetchDataCustom(
      moment(picker.startDate).format('DD/MM/YYYY'),
      moment(picker.endDate).format('DD/MM/YYYY')
    )
    this.setState(
      {
        filterTable: {
          startDateFilter: moment(picker.startDate).format('DD/MM/YYYY'),
          endDateFilter: moment(picker.endDate).format('DD/MM/YYYY')
        }
      },
      () => {
        this.fetchChartDataCutom(
          moment(picker.startDate).format('YYYY-MM-DD'),
          moment(picker.endDate).format('YYYY-MM-DD')
        )
      }
    )
  }

  today = () => {
    this.setState({
      filterTable: {
        startDateFilter: moment().format('DD/MM/YYYY'),
        endDateFilter: moment().format('DD/MM/YYYY')
      },
      selectedPickerOption: 'Today'
    })

    if (this.props.todayData) {
      this.setState({
        isLoading: false,
        activityBoxData: this.props.todayData.statData,
        chartData: this.props.todayData.dataChart
      })
      this.handleApply()
    } else {
      // this.setState({
      //   isLoading: true
      // })
      this.props.getData(
        this.state.filterTable.startDateFilter,
        this.state.filterTable.endDateFilter,
        GET_DATA_TODAY
      )
    }
  }

  yesterday = async () => {
    this.setState(
      {
        filterTable: {
          startDateFilter: moment()
            .subtract(1, 'days')
            .format(),
          endDateFilter: moment()
            .subtract(1, 'days')
            .format()
        }
      },
      () => {
        if (this.props.yesterdayData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.yesterdayData.statData,
            chartData: this.props.yesterdayData.dataChart
          })
        } else {
          // this.setState({
          //   isLoading: true
          // })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_YESTERDAY
          )
        }
      }
    )
  }

  lastWeek = () => {
    this.setState(
      {
        filterTable: {
          startDateFilter: moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek'),
          endDateFilter: moment()
            .subtract(1, 'weeks')
            .endOf('isoWeek')
        }
      },
      () => {
        if (this.props.lastWeekData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.lastWeekData.statData,
            chartData: this.props.lastWeekData.dataChart
          })
        } else {
          // this.setState({
          //   isLoading: true
          // })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_LAST_WEEK
          )
        }
      }
    )
  }

  thisWeek = () => {
    const startOfWeek = moment().startOf('isoWeek')
    const endOfWeek = moment().endOf('isoWeek')
    this.setState(
      {
        filterTable: {
          startDateFilter: startOfWeek,
          endDateFilter: endOfWeek
        }
      },
      () => {
        if (this.props.thisWeekData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.thisWeekData.statData,
            chartData: this.props.thisWeekData.dataChart
          })
        } else {
          // this.setState({
          //   isLoading: true
          // })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_THIS_WEEK
          )
        }
      }
    )
  }

  thisMonth = () => {
    const startOfMonth = moment()
      .clone()
      .startOf('month')
    const endOfMonth = moment()
      .clone()
      .endOf('month')
    this.setState(
      {
        filterTable: {
          startDateFilter: startOfMonth,
          endDateFilter: endOfMonth
        }
      },
      () => {
        if (this.props.thisMonthData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.thisMonthData.statData,
            chartData: this.props.thisMonthData.dataChart
          })
        } else {
          // this.setState({
          //   isLoading: true
          // })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_THIS_MONTH
          )
        }
      }
    )
  }

  lastMonth = () => {
    const prevMonthFirstDay = moment()
      .subtract(1, 'months')
      .startOf('month')
    const prevMonthLastDay = moment()
      .subtract(1, 'months')
      .endOf('month')
      .clone()
      .endOf('month')
    this.setState(
      {
        filterTable: {
          startDateFilter: prevMonthFirstDay,
          endDateFilter: prevMonthLastDay
        }
      },
      () => {
        if (this.props.lastMonthData) {
          this.setState({
            isLoading: false,
            activityBoxData: this.props.lastMonthData.statData,
            chartData: this.props.lastMonthData.dataChart
          })
        } else {
          // this.setState({
          //   isLoading: true
          // })
          this.props.getData(
            this.state.filterTable.startDateFilter,
            this.state.filterTable.endDateFilter,
            GET_DATA_LAST_MONTH
          )
        }
      }
    )
  }

  handleChange = (e, { value }) => {
    if (value === 'Select custom') {
      this.setState({
        selectedPickerOption: value,
        filterTable: {
          startDateFilter: moment(),
          endDateFilter: moment()
        }
      })
      return
    }
    this.setState({ selectedPickerOption: value })
    switch (value) {
      case 'Today':
        this.today()
        break
      case 'Yesterday':
        this.yesterday()
        break
      case 'This week':
        this.thisWeek()
        break
      case 'Last week':
        this.lastWeek()
        break
      case 'This month':
        this.thisMonth()
        break
      case 'Last month':
        this.lastMonth()
        break
      default:
        this.today()
    }
  }

  handleCancel = () => {
    this.today()
  }

  closeSubscriptionModal = async () => {
    await this.setState({ showSubscriptionModal: false })
  }

  openSubscriptionModal = async () => {
    await this.setState({ showSubscriptionModal: true })
  }

  render() {
    return (
      <>
        <Dimmer active={this.state.isLoading} style={{ position: 'fixed' }}>
          <Loader />
        </Dimmer>
        <div className="fullwidth-container home_page">
          {this.state.loadTitle && (
            <Title
              upgradePlanData={!this.state.isTrial}
              openSubscriptionModal={this.openSubscriptionModal}
              closeSubscriptionModal={this.closeSubscriptionModal}
              showSubscriptionModal={this.state.showSubscriptionModal}
              plan_name={this.state.plan_name}
              subscriptionData={this.state.subscriptionData}
              data={this.state.titleContent}
            />
          )}
          <div className="homecontent-wrapper pt-5 mt-5">
            <div className="float-right mr-5 pr-2">
              <div className="date-range-parent custom-range-parent range-dropdown">
                <Dropdown
                  onChange={this.handleChange}
                  className="mr-3"
                  options={options}
                  selection
                  value={this.state.selectedPickerOption}
                />
                {this.state.selectedPickerOption === 'Select custom' ? (
                  <CommonDateRangePicker
                    handleApply={this.handleApply}
                    handleCancel={this.handleCancel}
                    initialSettings={{
                      startDate: this.state.filterTable.startDateFilter,
                      endDate: this.state.filterTable.endDateFilter,
                      maxDate: moment()
                    }}
                  />
                ) : null}
              </div>
            </div>
            {this.state.activityBoxData && (
              <div>
                <p className="default-text help-title help-subtitle-margin ">
                  {' '}
                  Call Status
                </p>
                <HomeContent
                  loading={this.changeLoadingState}
                  date={this.state.filterTable}
                  callData={this.state.activityBoxData}
                />
              </div>
            )}
          </div>
          <AnalyticsGraph date={this.state.filterTable}/>
          <MeetingOverView date={this.state.filterTable}/>
          <div>
            <p className="default-text help-title help-subtitle-margin ">
              {' '}
              Leads report{' '}
            </p>
            <div className="chart-wrapper all_activites">
              <ActivityChart
                loading={this.changeLoadingState}
                apiData={this.state.chartData}
                date={this.state.filterTable}
              />
            </div>
          </div>
          <div>
            <p
              className="default-text help-title help-subtitle-margin"
              style={{ margin: '10px 0 -20px' }}
            >
              Performance Report
            </p>
            <div className="homecontent-wrapper our_services">
              <ActivityBox
                loading={this.changeLoadingState}
                date={this.state.activityBoxData}
                type="Performance"
              />
            </div>
          </div>
          <div>
            <p
              className="default-text help-title help-subtitle-margin"
              style={{ margin: '10px 0 -20px' }}
            >
              Lead Status
            </p>
            <div className="homecontent-wrapper our_services">
              <ActivityBox
                loading={this.changeLoadingState}
                date={this.state.activityBoxData}
                type="leadStatus"
                filterDate={this.state?.filterTable}
              />
            </div>
          </div>
          {this.state.activityBoxData && (
              <div>
                <p className="default-text help-title help-subtitle-margin ">
                  <h5 className="font-weight-bold">Call Stats</h5>
                </p>
                <HomeContent
                  loading={this.changeLoadingState}
                  date={this.state.filterTable}
                  callData={this.state.activityBoxData}
                />
              </div>
            )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  personalLink: state.home.personalLink,
  todayData: state.home.todayData,
  yesterdayData: state.home.yesterdayData,
  thisWeekData: state.home.thisWeekData,
  lastWeekData: state.home.lastWeekData,
  thisMonthData: state.home.thisMonthData,
  lastMonthData: state.home.lastMonthData
})

export default connect(mapStateToProps, {
  getWidget,
  getData,
  getPersonalLink
})(Analytics)
