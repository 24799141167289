import React, { useEffect, useState } from 'react'
import CommonButtons from './CommonButtons'
import LinkIcon from '@mui/icons-material/Link'
import EmailIcon from '@mui/icons-material/Email'
import CodeOffIcon from '@mui/icons-material/CodeOff'
import { getProfile } from '../actions/settings'
import { connect } from 'react-redux'
import { CommonNotify } from './CommonNotify'
import { getWidget } from '../actions/home'
import Axios from 'axios'
import { CircularProgress } from '@mui/material'
import { Dimmer, Header, Input, Loader, Modal, TextArea } from 'semantic-ui-react'


function ShareLinkModal({
  isModalClose,
  profile,
  getProfile,
  widget,
  getWidget,
  setShareModel
}) {
  const [personalLink, setPersonalLink] = useState('')
  const [emailLink, setEmailLink] = useState('')
  const [websiteScript, setWebsiteScript] = useState('')
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (profile) {
      setPersonalLink(profile?.data?.personal_link)
    } else {
      getProfile && getProfile()
    }
  }, [profile])

  useEffect(() => {
    if (widget && widget?.data?.[0]) {
      let code = `<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${widget?.data?.[0]?.script_id}" src="https://widget.limecall.com/widget.js"> </script>`
      console.log('code', code)
      setWebsiteScript(code)
    } else {
      // getWidget && getWidget()
    }
  }, [widget])

  const onCopyText = async jsCode => {
    try {
      await navigator.clipboard.writeText(jsCode)
      CommonNotify('Copied!', 'success')
    } catch (err) {
      CommonNotify('Failed to copy!')
    }
  }

  const handleCopyLink = id => {
    const value = document.getElementById(id)?.value
    if (value) {
      onCopyText(value)
    } else {
      CommonNotify('Text is not available!', 'warning')
    }
  }
  const handleSendMail = async () => {
    if (emailLink) {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!emailRegex.test(emailLink)) {
        CommonNotify('Invalid email address!', 'warning')
        return
      } else {
        setLoader(true)
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/email-personal-link`
        Axios.post(url, { email: emailLink })
          .then(response => {
            setLoader(false)
            CommonNotify('Personal Link sent successfully!', 'success')
            setEmailLink("")
          })
          .catch(error => {
            setLoader(false)

            CommonNotify(error?.response?.data?.errors?.[0])
          })
      }
      await navigator.clipboard.writeText(emailLink)
    } else {
      CommonNotify('Please enter email address first!', 'warning')
    }
  }

  return (
    <div>
      <Modal
       className="share-link-modal"
       onClose={()=>setShareModel(false)}
       closeIcon
       open={isModalClose}
        size="tiny"
      >
        <Header content="Share Your Link" />
        <Modal.Content style={{ padding: '30px' }}>
          <div className="form-group">
            <label>Copy Your Link</label>
            <div className="share-link-input">
              <Input fluid id="link-prl" value={personalLink} />
              <CommonButtons primary onClick={() => handleCopyLink('link-prl')}>
                <LinkIcon />
              </CommonButtons>
            </div>
          </div>
          <div className="form-group">
          <label>Email Your Link <span className='danger'>*</span></label>
            <div className="share-link-input">
              <Input
                fluid
                placeholder="Enter email id"
                id="link-emails-data"
                value={emailLink}
                onChange={e => setEmailLink(e?.target?.value)}
              />
              <CommonButtons
                primary
                onClick={() => handleSendMail()}
                // onClick={() => handleCopyLink('link-emails-data')()}
              >
              {loader ? 
          <CircularProgress
            size={24}
            sx={{
              color: 'white',
            }}
          />:
          <EmailIcon />}
              </CommonButtons>
            </div>
          </div>
          <div className="form-group">
            <label>Add to Your Website</label>
            <div className="share-link-input">
              <TextArea
                rows={5}
                fluid
                id="link-website-link"
                value={websiteScript}
              />
              <CommonButtons
                primary
                onClick={() => handleCopyLink('link-website-link')}
              >
                <CodeOffIcon />
              </CommonButtons>
            </div>
          </div>
          <div className="form-group">
            <CommonButtons
              primary
              fluid
              content="Close"
              onClick={e => setShareModel(false)}
            />
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
const mapStateToProps = state => ({
  profile: state.settings.profile,
  widget: state.home.widget
})

export default connect(mapStateToProps, { getProfile, getWidget })(
  ShareLinkModal
)
