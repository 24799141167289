import React, { useState, useEffect } from 'react'
import { Image } from 'semantic-ui-react'
import PhoneInput from 'react-phone-input-2'
import GoogleButton from '../components/loginregister/GoogleButton'
import Recaptcha from 'react-recaptcha'
import axios from 'axios'
import ValidateRegApi from '../helpers/signup/ValidateRegApi'
import {
  validateEmail,
  validatePhoneNumber
} from '../helpers/signup/Validation'
import CommonButton from '../common/CommonButtons'
import { CommonNotify } from '../common/CommonNotify'
import PostRegister from '../helpers/signup/PostRegister'
import loginLogo from '../assets/images/limephone-logo.png'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Dimmer, Loader } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const blockedCountries = [
  'ukraine',
  'indonesia',
  'fro yugoslavia',
  'yugoslavia',
  'lithuania',
  'egypt',
  'romania',
  'bulgaria',
  'turkey',
  'russia',
  'pakistan',
  'nigeria',
  'indonesia',
  'singapore',
  'ghana',
  'hungary',
  'belarus',
  'latvia',
  'sloval republic'
]

const SignUpNew = props => {
  const [credentials, setCredentials] = useState({
    email: '',
    firstname: '',
    lastname: '',
    recaptcha: '',
    errors: {}
  })

  const [validate, setValidate] = useState(false)
  const [validEmail1, setValidEmail1] = useState(false)
  const [validEmail2, setValidEmail2] = useState(false)
  const [validPass, setValidPass] = useState(false)
  const [recaptcha, setRecaptcha] = useState('')
  const [register, setRegister] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [countryCode, setCountryCode] = useState('')
  const [countryN, setCountry] = useState('')
  const [disableReg, setDisableReg] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);
  const location=useLocation()


  useEffect(() => {
    const quick = window.location?.href?.split('?')?.[1] || ''
    if (quick === 'quick') {
      localStorage.setItem('quickSetupAc', 1)
    } else {
      localStorage.removeItem('quickSetupAc')
    }
  }, [])

  useEffect(() => {
    if (
      validPass === true &&
      validEmail1 === true &&
      validEmail2 === true &&
      validate === true
    ) {
      setValidate(false)

      PostRegister(credentials).then(res => {
        if (res.message.length) {
          setRegister(
            'Successfully account created please check the email for next step'
          )
        }
      })
    }
  }, [credentials, validEmail1, validEmail2, validPass, validate])

  useEffect(() => {
    document.title = 'Sign UP'
    setCredentials({
      ...credentials,
      recaptcha: recaptcha
    })
  }, [recaptcha])

  useEffect(() => {
    if (
      credentials.email !== '' &&
      credentials.firstname !== '' &&
      credentials.lastname !== '' &&
      credentials.recaptcha !== ''
    ) {
      setValidate(true)
      resetValidate()
    } else {
      setValidate(false)
    }
  }, [credentials])

  useEffect(() => {
    getGeoInfo()
  }, [])

  useEffect(() => {
    if (countryN == '') return
    const blockedCountry = blockedCountries.includes(countryN)
    setDisableReg(blockedCountry)
  }, [countryN])

  const getGeoInfo = () => {
    axios
      .get('https://extreme-ip-lookup.com/json/?key=uwLOpRK2oO0MeZLVjMHh')
      .then(response => {
        let data = response.data
        setCountryCode(data.countryCode?.toLowerCase())
        setCountry(data.country?.toLowerCase())
      })
      .catch(error => {
        console.log(error)
      })
  }

  const recaptchaLoaded = () => {}

  const verifyCallback = response => {
    setRecaptcha(response)
  }

  const handleInput = e => {
    const value = e.target.value
    const name = e.target.name

    if (name === 'email') {
      const isError = !validateEmail(value)
      setDisableSubmit(isError)
      setCredentials({
        ...credentials,
        [name]: value,
        errors: {
          ...credentials.errors,
          [name]: isError ? 'Please enter valid email' : null
        }
      })
    } else {
      setCredentials({
        ...credentials,
        [name]: value,
        errors: {
          ...credentials.errors,
          [name]: ''
        }
      })
    }
  }
  const handlePhoneNumber = e => {
    setCredentials({
      ...credentials,
      errors: {
        ...credentials.errors,
        ['phoneNumber']:
          e.length > 2 && (e.length < 12 || e.length > 12)
            ? 'Invalid phone number'
            : ''
      }
    })
    setDisableSubmit(
      e.length > 2 && (e.length < 12 || e.length > 12) ? true : false
    )
  }

  const setPhoneCred = value => {
    setCredentials({
      ...credentials,
      ['phone']: value,
      errors: {
        ...credentials.errors,
        ['phone']: ''
      }
    })
  }

  const resetValidate = () => {
    setValidEmail1(false)
    setValidEmail2(false)
    setValidPass(false)
  }

  const handleSubmit = async creds => {
    if (!credentials.email) {
      setCredentials({
        ...credentials,
        errors: { email: 'Please Enter Valid Email' }
      })
      return false
    } else if (!credentials.firstname) {
      setCredentials({
        ...credentials,
        errors: { firstname: 'Please Enter First Name' }
      })
      return false
    } else if (!credentials.lastname) {
      setCredentials({
        ...credentials,
        errors: { lastname: 'Please Enter Last Name' }
      })
      return false
    } else if (!credentials.recaptcha) {
      setCredentials({
        ...credentials,
        errors: { recaptcha: 'Please Select Recaptcha' }
      })
      return false
    }

    if (!credentials.phone) {
      setCredentials({
        ...credentials,
        errors: { phone: 'Please enter phone number' }
      })
      return
    }
    if (credentials.phone && credentials.phone?.length < 8) {
      setCredentials({
        ...credentials,
        errors: { phone: 'Please enter valid phone number' }
      })
      return
    }

    setCredentials({ ...credentials, errors: {} })
    setIsLoading(true)
    //setIsLoading(true)
    try {
      const bEmail = await ValidateRegApi(
        'email',
        credentials.email,
        'api/check-if-spam-email?email=' + credentials.email,
        'get'
      )

      const emailResponse = bEmail?.email

      //  if(emailResponse?.socialMatch	 === false){
      //   CommonNotify('email Id not exist ')
      //   return;
      //  }

      // if (emailResponse?.freeProvider === true) {
      //   setIsLoading(false)
      //   CommonNotify('Please try with a business email.')
      //   return;
      // }
      if (emailResponse?.disposable === true) {
        setIsLoading(false)
        CommonNotify('We block disposable emails. Please use an b2b email')
        return
      }
      // if (emailResponse?.valid !== true) {
      //   setIsLoading(false)
      //   CommonNotify('You are using disposable / invalid email ID')
      //   return;
      // }
      else if (bEmail?.risk?.level === 'high') {
        setIsLoading(false)
        CommonNotify('Invalid email. Please use a business email')

        return
      } else {
        const ValidateRegApiEmail = await ValidateRegApi(
          'email',
          credentials.email,
          'api/email-validation'
        )
        if (ValidateRegApiEmail?.errors?.length) {
          //setValidEmail1(true)
          setValidate(false)
          setValidEmail2(ValidateRegApiEmail?.errors?.[0])
          setIsLoading(false)
          CommonNotify(ValidateRegApiEmail?.errors?.[0])
        } else {
          setValidEmail2(true)
          const signUpApi = await signUP()
          setIsLoading(false)
          if (!signUpApi) {
            return
          }

          CommonNotify(
            'Successfully created account! Please check your email we have sent a verification link.',
            'success'
          )
          localStorage.setItem(
            'phone_numbers',
            JSON.stringify(signUpApi.data.data.phone_numbers)
          )

          localStorage.setItem('quick_setup', 1)
          localStorage.setItem('role_name', signUpApi.data.data.role.name)
          localStorage.setItem('access_token', signUpApi.data.data.access_token)
          localStorage.setItem('widget_id', signUpApi.data.data.widget_id)
          localStorage.setItem(
            'working_hours_status',
            signUpApi.data.data.working_hours_status
          )
          localStorage.setItem('plan_name', signUpApi.data.data.plan_name)
          localStorage.setItem('status', signUpApi.data.data.status)
          localStorage.setItem(
            'profile_pic',
            signUpApi.data.data.profile_image
              ? `${process.env.REACT_APP_BASE_APP_URL}/storage` +
                  signUpApi.data.data.profile_image
              : null
          )
          localStorage.setItem('first_name', signUpApi.data.data.first_name)
          localStorage.setItem('full_name', signUpApi.data.data.first_name)
          localStorage.setItem('last_name', signUpApi.data.data.last_name)
          localStorage.setItem('email', signUpApi.data.data.email)
          localStorage.setItem('id', signUpApi.data.data.id)
          localStorage.setItem('email_verification_status', '0')
          localStorage.setItem(
            'admin_verification_status',
            signUpApi.data.data.admin_verification_status
          )

          localStorage.setItem(
            'mobile_verification_status',
            signUpApi.data.data.mobile_verification_status
          )
          // props.history.push('/QuickSetup')
          window.location.href = '/email-verify'
        }
      }
    } catch (error) {
      setIsLoading(false)
      const errors = { ...error }
      if (errors?.response?.data?.errors) {
        // console.log("errors")
      } else {
        CommonNotify('Some thing went wrong')
      }
    }
  }

  const signUP = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/register-api`
    const fullName = credentials.firstname + ' ' + credentials.lastname
    const data = {
      email: credentials.email,
      full_name: fullName,
      platform: "limephone",
      official_phone_number: credentials.phone,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      'g-recaptcha-response': credentials.recaptcha,
      ...(location?.search?.replace('?referral=','') && {referral:location?.search?.replace('?referral=','')}) 

    }
    return axios.post(url, data).catch(error => {
      CommonNotify(error?.response?.data?.errors?.[0])
    })
  }

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  const circleRightCheckIcon =
    'https://www.pngall.com/wp-content/uploads/12/Green-Check-PNG-Image-HD.png'

  return (
    <>
    <Dimmer active={isLoading}>
      <Loader />
    </Dimmer>
    <div id="signup-modern">
      <div className="logo-container">
        <div className="logo">
          <Image src={loginLogo} />
        </div>
      </div>
      <div className="fullwidth-container sign-up-container">
        <div className="container">
          <div className="reg-box">
            {width > 768 ? (
              <section
                className="text-section"
                style={{
                  display: 'flex',
                  height: '85vh',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <div className="text-container">
                  <h4>Just steps away from your free 7 days.</h4>

                  <p className="para" style={{ marginBottom: '25px' }}>
                    So you can <b>talk</b>, <b>message</b>, and{' '}
                    <b>manage calls</b> in one beautiful workspace
                  </p>
                  <p className="para">
                    <img
                      style={{ width: '26px', marginRight: '6px' }}
                      src={circleRightCheckIcon}
                      className="icon-check"
                    />{' '}
                    &nbsp;Keep personal and business calls separate.
                  </p>
                  <p className="para">
                    <img
                      style={{ width: '26px', marginRight: '6px' }}
                      src={circleRightCheckIcon}
                      className="icon-check"
                    />
                    &nbsp;Call routing, statistics, recording.
                  </p>
                  <p className="para">
                    <img
                      style={{ width: '26px', marginRight: '6px' }}
                      src={circleRightCheckIcon}
                      className="icon-check"
                    />
                    &nbsp;Add additional worldwide LimePhone numbers anytime.
                  </p>
                  <p className="para">
                    <img
                      style={{ width: '26px', marginRight: '6px' }}
                      src={circleRightCheckIcon}
                      className="icon-check"
                    />{' '}
                    &nbsp;Receive calls on app, browser, sip or your mobile
                    number.
                  </p>
                  <p className="para">
                    <img
                      style={{ width: '26px', marginRight: '6px' }}
                      src={circleRightCheckIcon}
                      className="icon-check"
                    />
                    &nbsp;Two way business text messages.
                  </p>
                  <p className="para">
                    <img
                      style={{ width: '26px', marginRight: '6px' }}
                      src={circleRightCheckIcon}
                      className="icon-check"
                    />{' '}
                    &nbsp;Easily manage your users and teams
                  </p>
                  <p className="para">
                    <img
                      style={{ width: '26px', marginRight: '6px' }}
                      src={circleRightCheckIcon}
                      className="icon-check"
                    />{' '}
                    &nbsp;Inbuilt lead management and qualification
                  </p>
                  <p className="para">
                    <img
                      style={{ width: '26px', marginRight: '6px' }}
                      src={circleRightCheckIcon}
                      className="icon-check"
                    />{' '}
                    &nbsp;Advanced integrations with CRM and automations{' '}
                  </p>
                  <div></div>
                </div>
              </section>
            ) : null}

            <section className="form-section sign-up-form-section" style={{ padding: '15px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  className="login-btn-wrapper"
                  style={{ marginBottom: '28px' }}
                >
                  <GoogleButton
                    content="Sign up with Google"
                    loading={setIsLoading}
                    login={false}
                  />
                </div>
              </div>

              <div className="or">
                <p className="or-text">or</p>
              </div>

              <div className="form-container cstmSingup">
                <form
                  onsubmit="event.preventDefault(); formValidation();"
                  id="signup-form"
                  action="#"
                  method="post"
                  data-hs-cf-bound="true"
                >
                  <h5 className="h5-lg" style={{ marginBottom: '35px' }}>
                    Create new account
                  </h5>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div className="form-group" id="fname-field" style={{}}>
                        <label for="form-fname" className="sr-only">
                          Your first name
                        </label>
                        <input
                          onChange={handleInput}
                          type="text"
                          className="form-control form-control-silver bottomspace"
                          id="form-fname"
                          name="firstname"
                          placeholder="First Name"
                          required=""
                        />
                      </div>
                      <div className="form-group" id="fname-field" style={{}}>
                        <label for="form-fname" className="sr-only">
                          Your last name
                        </label>
                        <input
                          onChange={handleInput}
                          type="text"
                          className="form-control form-control-silver bottomspace"
                          id="form-fname"
                          name="lastname"
                          placeholder="Last Name"
                          required=""
                        />
                      </div>
                    </div>

                    {credentials.errors.firstname && (
                      <div className="error__message email">
                        <p style={{ color: '#fe4502' }}>
                          {credentials.errors.firstname}{' '}
                        </p>{' '}
                      </div>
                    )}
                    {credentials.errors.lastname && (
                      <div className="error__message email">
                        <p style={{ color: '#fe4502' }}>
                          {credentials.errors.lastname}{' '}
                        </p>{' '}
                      </div>
                    )}
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      className="form-group"
                      id="fname-field"
                      style={{ flex: 1 }}
                    >
                      <label for="form-fname" className="sr-only">
                        Work Email
                      </label>
                      <input
                        onChange={handleInput}
                        type="text"
                        className="form-control form-control-silver bottomspace"
                        id="form-fname"
                        name="email"
                        placeholder="Work email"
                        required=""
                      />
                    </div>
                    {credentials.errors.email && (
                      <div className="error__message email">
                        <p style={{ color: '#fe4502' }}>
                          {credentials.errors.email}{' '}
                        </p>{' '}
                      </div>
                    )}
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      className="form-group"
                      id="fname-field"
                      style={{ flex: 1 }}
                    >
                      <label for="form-fname" className="sr-only">
                        Phone number
                      </label>
                      <PhoneInput
                        country={countryCode || 'us'}
                        placeholder="Phone number"
                        value={credentials.phone || ''}
                        onChange={phone => setPhoneCred(phone)}
                      />
                    </div>
                  </div>
                  {credentials?.errors?.phone && (
                    <div className="error__message email">
                      <p style={{ color: '#fe4502' }}>
                        {credentials?.errors?.phone}{' '}
                      </p>{' '}
                    </div>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '26px',
                      border: 'none',
                      marginTop: '26px',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Recaptcha
                      sitekey="6Lep-rgUAAAAAJM2pqeGqVpitmSh6RfMfJ8I9_cu"
                      onloadCallback={recaptchaLoaded}
                      verifyCallback={verifyCallback}
                      render="explicit"
                      style="transform:scale(0.77);-webkit-transform:scale(0.77);transform-origin:0 0;-webkit-transform-origin:0 0;"
                    />
                    {credentials.errors.recaptcha && (
                      <div className="error__message email">
                        <p style={{ color: '#fe4502' }}>
                          {credentials.errors.recaptcha}{' '}
                        </p>{' '}
                      </div>
                    )}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '0'
                    }}
                  >
                    {disableReg ? (
                      <CommonButton
                        onClick={() => {
                          CommonNotify('Country not supported')
                        }}
                        disabled
                        btnClass={'btn-login enable'}
                        content={'Country Not supported'}
                      />
                    ) : (
                      <CommonButton
                        onClick={() => {
                          handleSubmit(credentials)
                        }}
                        disabled={disableSubmit}
                        btnClass={'btn-login enable'}
                        content={!isLoading ? 'Create Account' : 'Loading...'}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginBottom: '26px'
                    }}
                  >
                    <div className="form-group" id="company-field">
                      By clicking{' '}
                      <b style={{ fontWeight: '600' }}>Create My Account</b>{' '}
                      above, you agree to our{' '}
                      <a
                        href="https://limecall.com/terms-conditions/"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://limecall.com/privacy-policy/"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </form>
              </div>
              <div
                style={{ textAlign: 'left', paddingLeft: '20px' }}
                className="form-group"
                id="company-field"
              >
                <a href="/login">Already have an account?</a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default SignUpNew
