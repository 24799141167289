import axios from 'axios'
import React, { useEffect, useState } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import { Dimmer, Loader } from 'semantic-ui-react'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import VerificationStatus from './modal/VerificationStatus'
import VerifiedNumberModal from './modal/VerifiedNumberModal'

const CallRouting = ({ prev, next,isNotSkip }) => {
  const [verifyNumber, SetVerifyNumber] = useState({
    full_number: '',
    phone_country: '',
    phone_number: ''
  })
  const [errors, setErrors] = useState('disable')
  const [onConfirmCode, setOnConfirmCode] = useState(false)
  const [open, setOpen] = useState(false)
  const [StatusOpen, SetStatusOpen] = useState(false)
  const [appsumoUser, setAppsumoUser] = useState(false)

  useEffect(() => {
    const utype = localStorage.getItem('is_appsumo')
    if (utype == 1) {
      setAppsumoUser(true)
    }
  }, [])

  const isModalOpen = () => {
    setOpen(true)
  }
  const isModalClose = () => {
    setOpen(false)
    SetStatusOpen(false)
  }

  const isModalCloseTwo = () => {
    setOpen(false)
    SetStatusOpen(false)
    next()
  }

  const onNextStep = () => {
    setOpen(false)
    SetStatusOpen(false)
    next()
  }
  const [otp, setOtp] = useState('')
  const [isLading, setIsLoading] = useState(false)
  const apiToken = localStorage.getItem('access_token')
  const onChangeCountry = (e, phone) => {
    const numberData = {
      full_number: e,
      phone_country: phone.countryCode,
      phone_number: e
    }
    SetVerifyNumber(numberData)
    setErrors(validate(numberData))
  }
  const validate = (name, value) => {
    switch (name) {
      case 'country':
        if (!value) {
          return 'First name is Required'
        } else {
          return ''
        }

      default: {
        return ''
      }
    }
  }
  const onVerifyNumber = () => {
    const data = verifyNumber
    if (data.full_number == '' || data.phone_number == '') {
      CommonNotify('Please enter phone number first', 'warning')
      return
    }

    setOnConfirmCode(true)
    setIsLoading(true)
    const Url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/send-verify-phone-number`

    axios
      .post(Url, data)
      .then(res => {
        setIsLoading(false)

        setOnConfirmCode(false)
        isModalOpen()
      })
      .catch(error => {
        const errorMessage = { ...error }
        setIsLoading(false)
        if (errorMessage.response?.data.errors) {
          CommonNotify(errorMessage.response?.data.errors[0])
          setErrors(errorMessage.response?.data.errors[0])
          setOnConfirmCode(false)
        } else {
          CommonNotify('OTP server not working...')
          setOnConfirmCode(false)
        }
      })
  }

  const increaseStep = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/increase-onboarding`
    axios
      .post(url, {})
      .then(res => {
        SetStatusOpen(true)
        setErrors('enable')
        setIsLoading(false)
      })
      .catch(err => {})
  }

  const onChangeInput = e => {
    const name = e.target.value
    setOtp(name)
  }
  const onOtpVerification = () => {
    setOnConfirmCode(true)
    setIsLoading(true)
    const Url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/verify-phone-number`
    const data = {
      verification_code: otp,
      full_number: verifyNumber.full_number
    }
    axios
      .post(Url, data)
      .then(res => {
        if (res?.data?.message == 'Successfully') {
          setOpen(false)
          increaseStep()
        next()

        }
        setOnConfirmCode(false)
      })
      .catch(error => {
        CommonNotify('OTP is not correct')
        setIsLoading(false)
      })
  }

  const onVerifyByCall = () => {
    setIsLoading(true)
    setIsLoading(true)
    const Url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/send-verify-phone-number`
    const data = verifyNumber
    axios
      .post(Url, data)
      .then(res => {
        setErrors('enable')
        setIsLoading(false)

        isModalOpen()
      })
      .catch(error => {
        const errorMessage = { ...error }
        setIsLoading(false)
        if (!errorMessage.response) {
          return CommonNotify('Some thing went wrong')
        }
        if (errorMessage.response.data.errors) {
          CommonNotify(errorMessage.response.data.errors[0])
        } else {
          CommonNotify('Some thing went wrong')
        }
      })
  }

  return (
    <div className="call-routing h-100 d-flex flex-column" >
      <Dimmer active={isLading}>
        <Loader />
      </Dimmer>
      {/* <div className="call-title-holder">
        <p className="title-text">Set up your calling wizard</p>
      </div> */}
      <div className="call-routing-content my-auto">
        <h3 className="call-routing-text">Verify your number</h3>
        <p className="call-routing-description">
          {/* to start receiving calls from your leads */}
          to start forwarding calls to your number
        </p>
        <div className="phone-number-holder">
          <ReactPhoneInput
            inputExtraProps={{
              name: 'phone',
              required: true,
              autoFocus: true
            }}
            onChange={(e, phone) => onChangeCountry(e, phone)}
            country={'us'}
            name="country"
          />
          <CommonButtons
            content="Verify"
            background="blue"
            btnClass="btn-verify"
            onClick={onVerifyNumber}
          />
          <VerifiedNumberModal
            open={open}
            isModalOpen={onVerifyNumber}
            isModalClose={isModalClose}
            Title="Please Enter the code"
            onChangeInput={e => onChangeInput(e)}
            onOtpVerification={onOtpVerification}
            loading={onConfirmCode}
          />

          <VerificationStatus
            open={StatusOpen}
            isModalOpen={onNextStep}
            isModalClose={isModalCloseTwo}
            Title="Please Enter the code"
            onOtpVerification={onNextStep}
            loading={onConfirmCode}
          />
        </div>
        {errors ? <span className="requiredText">* required</span> : ''}
        <div className="code-holder">
          <CommonButtons content="Resend Code" onClick={onVerifyNumber} />
          <CommonButtons
            content="Verify via call instead"
            onClick={onVerifyByCall}
          />
        </div>
      </div>
      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        <CommonButtons
          type="button"
          content="Back"
          btnClass="btn-modal-style btn-back"
          onClick={prev}
        />
           {/* <CommonButtons
            type="button"
            content="Continue"
            className={errors == 'disable' ? 'disableBtn' : 'btnInner'}
            background="blue"
            onClick={next}
          /> */}
        {/* {isNotSkip ? (
          <CommonButtons
            type="button"
            content="Continue"
            className={errors == 'disable' ? 'disableBtn' : 'btnInner'}
            background="blue"
            onClick={next}
          />
        ) : (
          <CommonButtons
            type="button"
            content="Skip"
            className={errors == 'disable' ? 'disableBtn' : 'btnInner'}
            background="blue"
            onClick={next}
          />
        )} */}
      </div>
    </div>
  )
}

export default CallRouting
