export const GET_WIDGET = "GET_WIDGET";
export const GET_CONVERSATIONS = "GET_CONVERSATIONS";
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_PIPELINES = "GET_PIPELINES";
export const GET_PERSONAL_LINK = "GET_PERSONAL_LINK";
export const GET_DATA_TODAY = "GET_DATA_TODAY";
export const GET_DATA_YESTERDAY = "GET_DATA_YESTERDAY";
export const GET_DATA_THIS_WEEK = "GET_DATA_THIS_WEEK";
export const GET_DATA_LAST_WEEK = "GET_DATA_LAST_WEEK";
export const GET_DATA_THIS_MONTH = "GET_DATA_THIS_MONTH";
export const GET_DATA_LAST_MONTH = "GET_DATA_LAST_MONTH";
export const GET_NUMBERS = 'GET_NUMBERS'
export const GET_PROFILE_DATA = "GET_PROFILE_DATA";
export const GET_NOTIFICATIONS_WIDGET = "GET_NOTIFICATIONS_WIDGET";
export const GET_NOTIFICATIONS_ACTIONS = "GET_NOTIFICATIONS_ACTIONS";
export const GET_CALLFORWADING_SETTINGS = "GET_CALLFORWADING_SETTINGS";
export const GET_TEAM_MEMBER = "GET_TEAM_MEMBER";
export const GET_WIDGET_DAYS = "GET_WIDGET_DAYS";
export const GET_USER_INTEGRATIONS = "GET_USER_INTEGRATIONS";
export const GET_TEAMS = "GET_TEAMS";
export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS";
export const GET_CHARGE_LOG = "GET_CHARGE_LOG";
export const GET_MEMBER_ROLES = "GET_MEMBER_ROLES";
export const GET_PAYMENT_LOG = "GET_PAYMENT_LOG";
export const GET_CUSTOMER_INVOICES = "GET_CUSTOMER_INVOICES";
export const GET_BILLING_INFO = "GET_BILLING_INFO";
export const GET_PAYMENT_METHOD = "GET_PAYMENT_METHOD";
export const GET_WEB_HOOK = "GET_WEB_HOOK";
export const GET_WIDGET_INSTALL = "GET_WIDGET_INSTALL";
export const GET_WIDGET_COUNTRIES = "GET_WIDGET_COUNTRIES";
export const GET_SMS_PLACEHOLDERS = "GET_SMS_PLACEHOLDERS";
export const GET_CALLER_ID = "GET_CALLER_ID";
export const GET_WIDGET_SETTINGS = "GET_WIDGET_SETTINGS";
export const GET_DIALER_STATUS = "GET_DIALER_STATUS";
export const CHANGE_DIALER_STATUS = "CHANGE_DIALER_STATUS";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const GET_PLANS = "GET_PLANS";
export const TEAMS_ACTION = "TEAMS_ACTION";
export const SET_FLAG = "SET_FLAG";
