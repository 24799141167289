import React, { Fragment, useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import DialerImage from '../assets/images/flaticons/dialer.png'
import callbackIcon from '../assets/images/callback-icon.png'
import CloseIcon from '@mui/icons-material/Close';
import SendMessageModal from '../common/SendMessageHeader'

import axios from 'axios'
import { connect, useDispatch } from 'react-redux'
import { getDialer } from '../actions/dialer'
import { getSubscription, getWidget } from '../actions/home'
import { CommonNotify } from './CommonNotify'
import CommonSelect from './CommonSelect'
import CallbackModal from './CallbackModal'
import EmailSend from './EmailSend'
import './ToggleSwitch.scss'
import ProfileModal from './ProfileModal'
import WifiCallingIcon from '@mui/icons-material/WifiCalling';

import NotificationModal from './NotificationModal'
import ToggleSwitch from './ToggleSwitch'
import { Link } from 'react-router-dom'
import { Button, Popover,  } from '@mui/material'
import { GET_PIPELINES } from '../actions/types'
const role_name = localStorage.getItem('role_name')

export const isAdmin = () => {
  const adminRoles = ['Admin', 'Operation Manager', 'Widget Manager', 'Manager']
  const role_name = localStorage.getItem('role_name')
  return adminRoles.includes(role_name)
}

const Title = props => {
  const dispatch=useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userStatus, setUserStatus] = useState(false)
  const [location, setlocation] = useState('')
  const [upgradeNeeded, setUpgradeNeeded] = useState(false)
  const [upgradeButton, setUpgradeButton] = useState(false)
  const [upgradeTitle, setUpgradeTitle] = useState('')
  const [freeTitle, setFreeTitle] = useState('')
  const [openSms, setOpenSms] = useState(false)
  const [openCallback, setOpenCallback] = useState(false)
  const [showInstallationBanner, setShowInstallationBanner] = useState(false)
  const adminVerification = localStorage.getItem('admin_verification_status')
  const titleRedirect = ['Your account is currently pending verification']
  const [script, setScript] = useState('')
  const [model, setModel] = useState(false)
  const [textshow, setTextshow] = useState(false)
  const [open, setOpen] = useState(false)
  // useEffect(() => {
  //   fetchChecklistStatus()
  // }, [])

  const handleCloseModal = async () => {
    props.closeSubscriptionModal()
  }

  const handleOpenModal = async () => {
    props.openSubscriptionModal()
  }

  useEffect(() => {
    if (!props.widget) {
      return
    }
    fetchWidget()
    if(!props.widget?.data[0]?.installed){
      setTextshow(true)
    }
    if(props.widget?.data[0]?.script_id){
    

      setScript(`<script data-api-url="${process.env.REACT_APP_BASE_APP_URL}" data-api-key="${props.widget?.data[0].script_id}" src="https://widget.limecall.com/widget.js"> </script>`)
    }
  }, [props.widget])

  useEffect(() => {
        
    dispatch(getWidget())
    upgradePlan()
    if (!props.subscription) {
      getSubscription()
    }
    getFunnels()
  }, [])


  const getFunnels = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/widget/funnels`
    await axios
      .get(url)
      .then(res => {


        dispatch({
          type: GET_PIPELINES,
          payload: res?.data?.data
        })
      })
      .catch(error => { })
  }

  useEffect(() => {
    setLocation()
  }, [location, userStatus])

  const setLocation = async () => {
    const status = await localStorage.getItem('status')
    setUserStatus(status === '1' ? true : false)
    const location = window.location.href
    setlocation(location.split('/')[3])
  }

  const isModalClose = () => {
    setOpenSms(false)
  }

  const openDialer = () => {
    props.getDialer(!props.dialerStatus)
  }

  const callbackDialer = () => {
    setOpenCallback(!openCallback)
  }
  
  const fetchChecklistStatus = () => {
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/checklist`
    axios
      .get(url)
      .then(res => {
        let dt = res.data.data
        if (dt?.widget_installed === 0) {
          setShowInstallationBanner(true)
        } else {
          setShowInstallationBanner(false)
        }
      })
      .catch(err => {})
  }

  const fetchWidget = async () => {
    let script_id = await localStorage.getItem('data-api-key')
    if (!script_id) script_id = props.widget.data[0].script_id
  }

  const upgradePlan = async () => {
    const result = await localStorage.getItem('plan_name')
    const upgrade = await localStorage.getItem('upgrade_status')
    const upgradeTtl = await localStorage.getItem('upgrade_title')
    const upgradeButton = await localStorage.getItem('upgrade_button')
    const freeTitles = await localStorage.getItem('freeplan')
    if (result === 'app-sumo-p05') return false
    await setUpgradeTitle(upgradeTtl)


    // if(props.upgradePlanData){
    //   setUpgradeNeeded(false)
    // }else{
    //   const upgrade = await localStorage.getItem('upgrade_status')
    //   if(upgrade) {
    //     setUpgradeNeeded(true)
    //   }
    // }

    if (freeTitles) {
      setFreeTitle(freeTitles)
    } else {
      setFreeTitle('')
    }
    if (upgrade === '1') {
      setUpgradeNeeded(true)
    } else {
      setUpgradeNeeded(false)
    }

    if (upgradeButton == '1') {
      setUpgradeButton(true)
    } else {
      setUpgradeButton(false)
    }
  }

  const fetchUrl = () => {
    const host = window.location.hostname
    let url = ''
    if (host === 'localhost') {
      const port = window.location.port
      url = `//${host}:${port}`
    } else {
      url = `//${host}`
    }
    return url
  }

  const setAvailability = async (e, val) => {
    //  console.log('Vals ::::',e, val);
    setUserStatus(val)
    localStorage.setItem('status', val ? 1 : 0)
    setAvailabilityStatus(val)
  }

  const setAvailabilityStatus = async status => {
    const id = await localStorage.getItem('id')
    const data = {}
    const url = `${
      process.env.REACT_APP_BASE_APP_URL
    }/api/v1/team/update-member-availability/${id}/${status ? 1 : 0}`

    axios
      .post(url, data)
      .then(res => {})
      .catch(err => {
        CommonNotify('Some error occured')
      })
  }
  const showWarn=['get-started','home','leads','manageleads','widgets']
  return (
    <div
      className={
        props.meetingLocation
          ? 'title-container header_fixed headerMeeting'
          : showInstallationBanner && textshow && showWarn?.includes(location) 
          ? 'title-container title-container-updated  header_fixed'
          : 'title-container header_fixed'
      }
    >
      <ReactTooltip />
      <style>
        {`
        #hn-changelog-widget {
          inset: 50px 50px auto auto !important;
        }
        `}
      </style>
      {props.data.type === 'image' ? (
        <Fragment>
          <div className="title-wrapper">
            <img src={props.data.titleOne} className="img-icon" alt="logo" />
            <div className="title-two">{props.data.titleTwo}</div>
          </div>
          {props.dataInvoice === 'Invoices' ? (
            <div
              className="invoice-select-wrapper filter_section"
              style={{ marginRight: 100 }}
            >
              <p className="saved-filter-text">Saved Filters</p>
              <CommonSelect name="invoice" options={['Basic', 'Premium']} />
            </div>
          ) : null}
        </Fragment>
      ) : (
        <Fragment>
          <div className="welcome-title">
            <div className="title-one main_title">{props.data.titleOne}</div>
            <div className="title-two">{props.data.titleTwo}</div>
          </div>
        </Fragment>
      )}

{showWarn?.includes(location) &&  <>
       

          <div className="home-toggle">
            {showInstallationBanner && (
              <Link
                to={`/settings/installation`}
                data-tip="Redirect to the integration page"
                style={{
                  marginRight: '20px',
                  verticalAlign: 'top',
                  color: 'red',
                  fontSize: '16px'
                }}
              >
                Tracking script not installed. Show instructions
              </Link>
            )}
            {freeTitle === 'Your account is currently on Free plan' && (
              <a>
                <span
                  style={{
                    backgroundColor: 'rgb(0, 113, 235)',
                    padding: '5px',
                    borderRadius: '5px',
                    marginLeft: '5px',
                    marginRight: '5px'
                  }}
                  className="cursor-pointer"
                  onClick={() => props?.openModal()}
                >
                  Upgrade
                </span>
              </a>
            )}

            <a
              href={`/settings/billing/subscription`}
              target="_blank"
              data-tip="Credits are used for outbound calls & sms"
              style={{
                marginRight: '20px',
                verticalAlign: 'top',
                color: 'green',
                fontSize: '17px'
              }}
            >
              ${props?.subscription?.data?.credits_in_dollars
                ? props.subscription.data.credits_in_dollars.toFixed(2)
                : 0}
            </a>

           
           

            <ToggleSwitch
              onChange={setAvailability}
              checked={userStatus}
              Name="newsletter"
            />
          </div>
        </>}

{openCallback && (
                              <CallbackModal
                                isModalClose={() => {
                                  setTimeout(() => {
                                    setOpenCallback(false)
                                  }, 300)
                                }}
                                isModalOpen={() => console.log(`open`)}
                                open={openCallback}
                                // dropDownData={dropDownData || []}
                                // leadData={leadAbout}
                                // phone_numbber={leadAbout?.phone_number}
                              />
                            )}
      {role_name === 'Admin' ? (
        <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center'  }}>
          {/* {upgradeNeeded && (
              <div
              onClick={handleOpenModal}
              style={{ display: 'block', position: 'relative' }}
            >
              <Link className="home-card-button sky-blue" to="#">
                Upgrade
              </Link>
            </div>

          ) }          */}


         
          
          <Button onMouseEnter={(e) => setOpen(e.currentTarget)}
            onMouseLeave={() => setOpen(null)} style={{marginLeft:"10px", marginRight:'30px'}} variant="contained" onClick={() => openDialer()} startIcon={<WifiCallingIcon />}>
                Dialer
              </Button>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none'
            }}
            open={!!open}
            anchorEl={open}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={() => setOpen(null)}
            disableRestoreFocus
          >
            <div style={{ marginTop:'5px',padding: '15px', width: '300px', background:'#0071eb',color:'#fff'}}>
              <div style={{display:'flex', justifyContent:'space-between'}}>
              <h5 style={{fontWeight:600}}>Get ready for the call</h5>
            </div>
              <p> Select and test your microphone and speakers before making a call</p>
              
            </div>
          </Popover>
            <a
              href="javascript:void(0)"
              data-tip="Send SMS & MMS"
              onClick={() => setOpenSms(true)}
            >
              <i
                className="fa fa-envelope"
                aria-setsize="20"
                style={{ color: '#2185d0', fontSize: 21, marginRight: 10 }}
                aria-hidden="true"
              ></i>{' '}
            </a>

          <div>
            <i
              id="changelogWidgetID"
              data-tip="Update logs"
              className="fa fa-info-circle"
              aria-setsize="20"
              style={{ color: '#2185d0', fontSize: 21, marginLeft: 10 }}
              aria-hidden="true"
            ></i>
          </div>
          <div>
            <NotificationModal name="Notification" />
              <SendMessageModal isModalClose={isModalClose} open={openSms} />
          </div>
          <div className="menu-link">
            <div className="icon-wrapper">
              <ProfileModal name="Profile" />
            </div>
            {/* <div className="hover-name-wrapper">
              <i className="fas fa-caret-left"></i>
              <p>Profile</p>
            </div> */}
          </div>
        </div>
      ) : null}
        {model &&
       <EmailSend script={script} open={model} onClose={()=>setModel(!model)}/>
        
        }
    </div>
  )
}

const mapStateToProps = state => ({
  widget: state.home.widget,
  subscription: state.home.subscription,
  dialerStatus: state.dialer.currentStatus
})

export default connect(mapStateToProps, {
  getWidget,
  getSubscription,
  getDialer
})(Title)
