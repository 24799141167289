import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import PhoneInput from 'react-phone-input-2'
export default function YourPhone(props) {
  const [number, setNumber] = useState(null)
  const [details, setDetails] = useState({})

  useEffect(() => {
    getNumber()
  }, [])

  const getNumber = async () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-user-numbers`
    Axios.get(url)
      .then(res => {
        setDetails(res?.data?.data?.[0] || {})
        setNumber(
          res?.data?.data[0]?.phone_number ||
            res?.data?.data?.phoneNumbers?.[0] ||
            null
        )
      })
      .catch(error => {
        CommonNotify(
          error?.response?.data?.errors[0] || 'Something went to wrong!'
        )
      })
  }

  return (
    <>
      <div className="assigned-screen">
        <h2>We've assigned you a 1st number</h2>
        <div className="assigned-inner">
          <div className="number number-onbording">
            {number ? (
              <PhoneInput
                international
                disabled
                className="text-color"
                country={
                  details?.country ? details?.country?.toLowerCase() : 'us'
                }
                value={number}
              />
            ) : (
              'N/A'
            )}
          </div>
          <h6>This number is now yours!</h6>
          <p>
            Feel free to test inbound and outbound calls with this number. And
            don’t worry, you can add and remove as many numbers as you need
          </p>
          {/* <Button primary onClick={() => props?.next()}>
            Continue
          </Button> */}
        </div>
      </div>
      <div
        className="quicksetup-footer footer-button mt-auto"
        style={{ justifyContent: 'space-between' }}
      >
        {props?.btnBack === false ? (
          <span></span>
        ) : (
          <CommonButtons
            content="Back"
            btnClass="btn-modal-style btn-back"
            onClick={props.prev}
          />
        )}
        <CommonButtons
          type="button"
          content="Continue"
          background="blue"
          onClick={props.next}
          // loading={loading}
        />
      </div>
    </>
  )
}
