import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Table, Modal, Dimmer, Loader } from 'semantic-ui-react'
import CommonButtons from '../common/CommonButtons'
import { CommonNotify } from '../common/CommonNotify'
import close from '../assets/images/close.png'
import moment from 'moment'
import { Button } from '@mui/material'
import noCampaign from '../assets/images/no-compaign.png'
import CampaignIcon from '@mui/icons-material/Campaign';
import TextFieldsIcon from '@mui/icons-material/TextFields';
export default function SMSCampaignsHistory({onBroadCast}) {
  const [campaignsList, setCampaignsList] = useState([])
  const [campaignsData, setCampaignsData] = useState([])
  const [loader, setLoader] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [details, setDetails] = useState(null)
  const { messages } = details || {}

  useEffect(() => {
    if (modal) {
      getCampaignsById()
    }
  }, [modal])

  useEffect(() => {
    getCampaignsList()
  }, [])

  const getCampaignsById = async () => {
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/campaign/${selectedRecord}`
    axios
      .get(url)
      .then(res => {
        setLoader(false)
        if (res?.data?.data) {
          setDetails(res?.data?.data)
        }
      })
      .catch(error => {
        setLoader(false)
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
      })
  }

  const getCampaignsList = async () => {
    setLoader(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/contact/campaigns`
    axios
      .get(url)
      .then(res => {
        setLoader(false)
        if (res?.data?.data) {
          setCampaignsData(res?.data?.data)
          handleFilterData(res?.data?.data)
        }
      })
      .catch(error => {
        CommonNotify(
          error?.response?.data?.errors?.[0] || 'Somethign went to wrong!'
        )
        setLoader(false)
      })
  }

  const handleFilterData = value => {
    if (value?.on_time?.length || value?.schedule?.length) {
      const allData = [...value?.on_time, ...value?.schedule]
      const filterCommentField = allData?.sort(function(a, b) {
        if (a?.id < b?.id) {
          return -1
        }
        if (a?.id > b?.id) {
          return 1
        }
        return 0
      })
      setCampaignsList(filterCommentField?.reverse() || [])
    } else {
      setCampaignsList([])
    }
  }

  const handleOpenReportModal = value => {
    setModal(true)
    setSelectedRecord(value || null)
  }

  return (
    <div className="history-campaign">
      <Dimmer active={loader} style={{ position: 'fixed' }}>
        <Loader />
      </Dimmer>
      {campaignsList &&
              campaignsList?.length > 0                             ? 
              <>
              <h3 className="mb-4">SMS campaigns</h3>
      <div>
        <Table className="border-0">
          <Table.Header style={{ padding: '12px 15px' }}>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Created at</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Name (+type)</Table.HeaderCell>
              <Table.HeaderCell>Recipients</Table.HeaderCell>
              <Table.HeaderCell>Delivered</Table.HeaderCell>
              <Table.HeaderCell>Failed</Table.HeaderCell>
              <Table.HeaderCell>Cancelled</Table.HeaderCell>
              <Table.HeaderCell>Pending</Table.HeaderCell>
              <Table.HeaderCell>ACTIONS</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body style={{ padding: '10px 12px' }}>
            {campaignsList &&
              campaignsList?.length > 0 &&
              campaignsList?.map((list, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>#{list?.id || 0}</Table.Cell>
                    <Table.Cell>
                      {list?.type === 'SCHEDULE'
                        ? list?.scheduled_at?.split('-')[0] &&
                          moment(
                            new Date(list?.scheduled_at?.split(' - ')[0])
                          ).format('DD MMM YYYY')
                        : list?.created_at
                        ? moment(list?.created_at).format('DD MMM YYYY')
                        : ''}{' '}
                      {list?.type === 'SCHEDULE'
                        ? list?.scheduled_at?.split('-')[0] &&
                          moment(
                            new Date(list?.scheduled_at?.split(' - ')[0])
                          ).format('h:mm')
                        : list?.created_at
                        ? moment(new Date(list?.created_at)).format('h:mm')
                        : ''}
                    </Table.Cell>
                    <Table.Cell>{list?.status || ''}</Table.Cell>
                    <Table.Cell>
                      {list?.name || '-'}
                      <br></br>
                      {list?.type || ''}
                    </Table.Cell>
                    <Table.Cell>{list?.total_contacts || '0'}</Table.Cell>
                    <Table.Cell>{list?.total_sent_msgs || '0'}</Table.Cell>
                    <Table.Cell>{list?.total_failed_msgs || '0'}</Table.Cell>
                    <Table.Cell>{list?.total_canceled_msgs || '0'}</Table.Cell>
                    <Table.Cell>{list?.total_pending_msgs || '0'}</Table.Cell>

                    <Table.Cell>
                      <CommonButtons
                        content="report"
                        background="blue"
                        btnClass="btn-send"
                        onClick={() => handleOpenReportModal(list?.id)}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table>
      </div>
      </>
      :
        !loader ? <>
          <div className='d-flex'>
            <div>
              <h3 className='font-weight-bold mt-5'>Reach Everyone with a Single Text Broadcast</h3>
              <h5 className='mt-5'>Text tens, hundreds or thousands of contacts at once with ease. Craft your message, select the audience and click send.</h5>
              <div className='mt-5 mb-5'>
                <li>Send Personalized message directly to customize in minutes</li>
                <li>Get detailed analytics on each message to track performance</li>
                <li>Great for reaching big or small groups pf contacts</li>
              </div>
              <div className='mb-3' >
                <Button variant='contained' onClick={() => onBroadCast()}> <CampaignIcon className='mr-2' /> New Broadcast</Button>
              </div>
              {/* <div>
            <Button variant='outlined'> <TextFieldsIcon className='mr-2'/>Start with a template</Button>
            </div> */}
            </div>
            <img src={noCampaign} alt="no-campaign" />
          </div>
        </> : null
      }
      {modal && (
        <Modal
          onClose={() => setModal(false)}
          onOpen={() => setModal(true)}
          open={modal}
          style={{ width: '1220px' }}
        >
          <div
            style={{
              overflowY: 'scroll',
              minHeight: '600px',
              maxHeight: '700px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                borderBottom: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: 'grey',
                padding: 15,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <h1
                style={{
                  fontSize: 23,
                  fontWeight: 'bold',
                  margin: '0 auto',
                  textAlign: 'center',
                  borderBottomWidth: 1,
                  borderBottomColor: 'gray'
                }}
              >
                SMS Report
              </h1>
              <div className="close-icon" onClick={() => setModal(false)}>
                <img src={close} alt="close" />
              </div>
            </div>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Sender id</Table.HeaderCell>
                  <Table.HeaderCell>Contact</Table.HeaderCell>
                  <Table.HeaderCell>Delivery Status</Table.HeaderCell>
                  <Table.HeaderCell>Failure reason</Table.HeaderCell>
                  <Table.HeaderCell>Country</Table.HeaderCell>
                  <Table.HeaderCell>Cost</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {messages &&
                  messages?.length > 0 &&
                  messages?.map((log, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {(log?.created_at &&
                            moment(log?.created_at).format(
                              'DD MMM YYYY, h:mm a'
                            )) ||
                            ''}
                        </Table.Cell>
                        <Table.Cell>{log?.from}</Table.Cell>
                        <Table.Cell>{log?.to}</Table.Cell>
                        <Table.Cell>{log?.status}</Table.Cell>
                        <Table.Cell>
                          {log?.failed_reasons ? log?.failed_reasons : '-'}
                        </Table.Cell>
                        <Table.Cell>{log?.country}</Table.Cell>
                        <Table.Cell>${log?.cost?.toString()}</Table.Cell>
                      </Table.Row>
                    )
                  })}
              </Table.Body>
            </Table>
          </div>
        </Modal>
      )}
    </div>
  )
}
