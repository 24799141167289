import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import call from '../assets/images/call.png'
import web from '../assets/images/web.png'
import chat from '../assets/images/chat.png'
import transition from '../assets/images/transition.png'
import event from '../assets/images/event.png'
import message from '../assets/images/ai-message.png'
import email from '../assets/images/ai-email.png'
import ReactApexChart from 'react-apexcharts'
import axios from 'axios'
import moment from 'moment'

export default function AnalyticsGraph({ date }) {
  const [TypeLead, setTypeLead] = useState({
    series: [44, 55, 41, 17, 15, 16, 17],
    options: {
      chart: {
        width: 380,
        type: 'donut',
        dropShadow: {
          enabled: true,
          color: '#111',
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2
        }
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      labels: [
        'Calls',
        'Form',
        'Chats',
        'Callback',
        'Webhook',
        'Messages',
        'Emails'
      ],
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8
        }
      },
      states: {
        hover: {
          filter: 'none'
        }
      },
      theme: {
        palette: 'palette2'
      },
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  })
  const [accounting, setAccounting] = useState({
    series: [
      {
        name: 'Actual',
        data: [
          {
            x: '2011',
            y: 12,
            goals: [
              {
                name: 'Expected',
                value: 14,
                strokeWidth: 2,
                strokeDashArray: 2,
                strokeColor: '#775DD0'
              }
            ]
          },
          {
            x: '2012',
            y: 44,
            goals: [
              {
                name: 'Expected',
                value: 54,
                strokeWidth: 5,
                strokeHeight: 10,
                strokeColor: '#775DD0'
              }
            ]
          },
          {
            x: '2013',
            y: 54,
            goals: [
              {
                name: 'Expected',
                value: 52,
                strokeWidth: 10,
                strokeHeight: 0,
                strokeLineCap: 'round',
                strokeColor: '#775DD0'
              }
            ]
          },
          {
            x: '2014',
            y: 66,
            goals: [
              {
                name: 'Expected',
                value: 61,
                strokeWidth: 10,
                strokeHeight: 0,
                strokeLineCap: 'round',
                strokeColor: '#775DD0'
              }
            ]
          },
          {
            x: '2015',
            y: 81,
            goals: [
              {
                name: 'Expected',
                value: 66,
                strokeWidth: 10,
                strokeHeight: 0,
                strokeLineCap: 'round',
                strokeColor: '#775DD0'
              }
            ]
          },
          {
            x: '2016',
            y: 67,
            goals: [
              {
                name: 'Expected',
                value: 70,
                strokeWidth: 5,
                strokeHeight: 10,
                strokeColor: '#775DD0'
              }
            ]
          }
        ]
      }
    ],
    options: {
      chart: {
        height: 480,
        type: 'bar',
        toolbar: { show: false }
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      colors: ['#00E396'],
      dataLabels: {
        formatter: function(val, opt) {
          const goals =
            opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals

          if (goals && goals.length) {
            return `${val} / ${goals[0].value}`
          }
          return val
        }
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Actual', 'Expected'],
        markers: {
          fillColors: ['#00E396', '#775DD0']
        }
      }
    }
  })
  const [TypeStatus, setStatusLead] = useState({
    series: [44, 55, 41, 17],
    options: {
      chart: {
        width: 480,
        type: 'donut',
        dropShadow: {
          enabled: true,
          color: '#111',
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2
        }
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      labels: ['Qualified', 'Pending', 'Not Qualified', 'Spam','  Assigned','    Closed '],
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8
        }
      },
      states: {
        hover: {
          filter: 'none'
        }
      },
      theme: {
        palette: 'palette2'
      },
      legend: {
        position: 'bottom'
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  })
  const [values, setValues] = useState({})

  useEffect(() => {
    if (values && Object?.keys(values)?.length) {
      const type = [
        values?.phone_calls || 0,
        values?.web_forms || 0,
        values?.chats || 0,
        values?.callbacks || 0,
        values?.webhooks || 0,
        values?.messages || 0,
        values?.emails || 0
      ]
      const status = [
        values?.qualified_leads || 0,
        values?.pending_leads || 0,
        values?.not_qualified_leads || 0,
        values?.spam || 0,
        values?.assigned_leads || 0,
        values?.closed_leads || 0

      ]
      setStatusLead({
        ...TypeStatus,
        series: status
      })
      setTypeLead({
        ...TypeLead,
        series: type
      })
    }
  }, [values])

  useEffect(() => {
    handleGetLeadData()
  }, [date])

  const handleGetLeadData = () => {
    var url = `${
      process.env.REACT_APP_BASE_APP_URL
    }/api/v1/leads/call-tracking-analytics?date_from=${moment(
      date?.startDateFilter
    ).format('YYYY/MM/DD')}&date_to=${moment(date?.endDateFilter).format(
      'YYYY/MM/DD'
    )}`
    axios
      .get(url)
      .then(res => {
        setValues(res?.data?.data)
      })
      .catch(error => {})
  }

  return (
    <div className="cardbox-wrapper deatil_box">
      <div className="container-trial">
        <Row className="mb-5">
          <Col md={4}>
            <div className="banner-description-box h-100">
              <h5 className="font-weight-bold mb-3">
                What type of calls are you getting?
              </h5>
              <ReactApexChart
                options={TypeLead.options}
                series={TypeLead.series}
                type="donut"
                width={'100%'}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="banner-description-box h-100">
              <h5 className="font-weight-bold mb-3">
                What is the status of the your calls?
              </h5>
              <ReactApexChart
                options={TypeStatus.options}
                series={TypeStatus.series}
                type="donut"
                width={'100%'}
              />
            </div>
          </Col>
          <Col md={4}>
            {/* <div className="banner-description-box h-100">
              <h5 className="font-weight-bold mb-3">
                Which account are converting better?
              </h5>
              <ReactApexChart
                options={accounting.options}
                series={accounting.series}
                type="bar"
                height={350}
              />
            </div> */}

            <div className="banner-description-box h-100">
              <h5 className="font-weight-bold mb-3">
                What type of calls are you getting?
              </h5>
              <ul className="lead-overview">
                <li>
                  <div className="lead-overview-title">
                    <img src={call} alt="" /> Phone calls
                  </div>
                  <div className="leads-count">
                    {values?.phone_calls || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={web} alt="" /> Web Forms
                  </div>
                  <div className="leads-count">
                    {values?.web_forms || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                {/* <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={chat} alt="" /> Chats
                  </div>
                  <div className="leads-count">{values?.chats || 0} Calls</div>
                  // <div className="lead-btn">
                  //   <button>View Calls</button>
                  // </div>
                </li> */}
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={transition} alt="" /> CallBack
                  </div>
                  <div className="leads-count">
                    {values?.callbacks || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={event} alt="" /> Webhooks
                  </div>
                  <div className="leads-count">
                    {values?.webhooks || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={message} alt="" /> Messages
                  </div>
                  <div className="leads-count">
                    {values?.messages || 0} Calls
                  </div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={email} alt="" /> Emails
                  </div>
                  <div className="leads-count">{values?.emails || 0} Calls</div>
                  {/* <div className="lead-btn">
                    <button>View Calls</button>
                  </div> */}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col md={4}>
            <div className="banner-description-box h-100">
              <h5 className="font-weight-bold mb-3">
                What type of leads are you getting?
              </h5>
              <ul className="lead-overview">
                <li>
                  <div className="lead-overview-title">
                    <img src={call} alt="" /> Phone calls
                  </div>
                  <div className="leads-count">
                    {values?.phone_calls || 0} Calls
                  </div>
                  <div className="lead-btn">
                    <button>View Calls</button>
                  </div>
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={web} alt="" /> Web Forms
                  </div>
                  <div className="leads-count">
                    {values?.web_forms || 0} Calls
                  </div>
                  <div className="lead-btn">
                    <button>View Calls</button>
                  </div>
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={chat} alt="" /> Chats
                  </div>
                  <div className="leads-count">{values?.chats || 0} Calls</div>
                  <div className="lead-btn">
                    <button>View Calls</button>
                  </div>
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={transition} alt="" /> CallBack
                  </div>
                  <div className="leads-count">
                    {values?.callbacks || 0} Calls
                  </div>
                  <div className="lead-btn">
                    <button>View Calls</button>
                  </div>
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={event} alt="" /> Webhooks
                  </div>
                  <div className="leads-count">
                    {values?.webhooks || 0} Calls
                  </div>
                  <div className="lead-btn">
                    <button>View Calls</button>
                  </div>
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={message} alt="" /> Messages
                  </div>
                  <div className="leads-count">
                    {values?.messages || 0} Calls
                  </div>
                  <div className="lead-btn">
                    <button>View Calls</button>
                  </div>
                </li>
                <li>
                  <div className="lead-overview-title">
                    {' '}
                    <img src={email} alt="" /> Emails
                  </div>
                  <div className="leads-count">{values?.emails || 0} Calls</div>
                  <div className="lead-btn">
                    <button>View Calls</button>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row> */}
      </div>
    </div>
  )
}
