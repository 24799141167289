import React, { useState, useEffect } from 'react'
import {
  Tab,
  Table,
  Dropdown,
  Icon,
  Popup,
  Image,
  Modal
} from 'semantic-ui-react'

import { useHistory } from 'react-router-dom'

import { CommonNotify } from '../../common/CommonNotify'
import axios from 'axios'
import moment from 'moment'

import hostImg from '../../assets/images/host-img.png'
import zoomImg from '../../assets/images/Zoom.png'
import blockIcon from '../../assets/images/block.png'
import GoogleMeet from '../../assets/images/Google-Meet.png'
import celenderIcon from '../../assets/images/celender.png'
import durationIcon from '../../assets/images/duration.png'
import deleteIcon from '../../assets/images/delete-icon.png'

import MissedCall from '../../assets/images/orangeCall.png'
import PickedCall from '../../assets/images/greenCall.png'
import cutCall from '../../assets/images/callRed.jpeg'

import CommonButtons from '../../common/CommonButtons'

import AddTagPopup from './leadRightPane/AddTagPopup'
import { isArray } from 'lodash-es'

const options = [
  { key: 1, text: 'Good Fit', value: 1 },
  { key: 2, text: 'Pending', value: 2 },
  { key: 3, text: 'Bad Fit', value: 3 }
]

const statusOptions = [
  { key: 1, text: 'Awaiting Review', value: 1 },
  { key: 2, text: 'In progress', value: 2 },
  { key: 3, text: 'Qualified', value: 4 },
  { key: 4, text: 'Closed', value: 3 }
]

const leadScoreOption = [
  { key: 1, text: 'Bad Fit', value: 'Bad fit' },
  { key: 2, text: 'Qualified', value: 'Qualified' }
]

const typeOptions = [
  { key: 1, text: 'Answered Calls', value: 'LIVE_CALL' },
  { key: 2, text: 'Missed Calls', value: 'MISSED_CALL' },
  { key: 3, text: 'Failed Calls', value: 'DIGITAL_CALL' },
  { key: 4, text: 'Messages', value: 'LEAVE_MESSAGE' },
  { key: 5, text: 'SMS', value: 'SMS' },
  { key: 6, text: 'Scheduled Calls', value: 'SCHEDULE_CALL' }
]

const MyLeadsTableContent = ({
  data,
  handleRightPaneOpen,
  next,
  prev,
  getNextPrev,
  allTags,
  reloadTable,
  leadStage,
  owners,
  updateTagCount,
  handleClickSideModal,
  onStageValueChanged,
  onChangeOwner,
  onStatusChangeHandler,
  onChangeInterseted,
  type
}) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [tags, setTags] = useState([])
  const [tagsUpdated, setTagsUpdated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [owner, setOwner] = useState([])
  const [selectedLead, setSelectedLead] = useState(0)
  const [selectedType, setSelectedType] = useState(1)
  const [deleteModal, setDeleteModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(true)
  const [playingAudio, setPlayingAudio] = useState(0)
  const [playingAudioContext, setPlayingAudioContext] = useState(null)
  const [cancelModal, setCancelModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tagCount, setTagCount] = useState(null)

  useEffect(() => {
    if (!tagsUpdated) return
    if (!tags.length) return
    saveTags()
  }, [tags])

  useEffect(() => {
    if (!allTags) return

    if (!allTags?.length) return

    let str = data.id
    if (str[0] == '#') {
      str = str.substring(1)
    }
    fetchTag(str)
  }, [allTags])

  const deleteModalView = async (bool, lead = 0) => {
    if (lead) {
      setSelectedLead(lead)
    }
    setDeleteModal(bool)
  }

  const updateModalView = async (bool, lead = 0) => {
    if (lead) {
      setSelectedLead(lead)
    }
    setUpdateModal(bool)
  }

  const onStatusChangeHandlerUpdate = (e, dat, data) => {
    onStatusChangeHandler(e, dat, data)
      .then(res => {
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update lead status')
      })
  }

  const onChangeIntersetedUpdate = (e, dat, data) => {
    onChangeInterseted(e, dat, data)
      .then(res => {
        reloadTable()
      })
      .catch(err => {
        CommonNotify('Not able to update  lead interseted')
      })
  }

  const handelClick = () => {
    const ar = getNextPrev(data.id)
    handleRightPaneOpen(data.id, ar[0], ar[1])
  }

  const addTag = (tag, index) => {
    let avilable = false

    tags.forEach(tg => {
      if (tg.tag.toLowerCase() === tag.toLowerCase()) {
        avilable = true
      }
    })

    if (avilable) return
    let newTags = [...tags]
    newTags.push(allTags[index])
    setTags(newTags)
    setTagsUpdated(true)
  }

  const playAudio = (id, url) => {
    // console.log("playing recording "+url)
    if (playingAudio) return

    setPlayingAudio(id)

    const audio = new Audio(url)
    audio.play()
    setPlayingAudioContext(audio)
  }

  const stopAudio = id => {
    if (playingAudio != id) return
    const audio = playingAudioContext
    audio.pause()
    setPlayingAudioContext(null)
    setPlayingAudio(0)
  }

  const onDeleteLeadHandler = async () => {
    setIsLoading(true)
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/delete-leads`
    var postId = [selectedLead]

    const postData = {
      ids: postId
    }

    axios
      .post(URL, postData)
      .then(res => {
        CommonNotify('Lead Deleted Successfully', 'success')

        setIsLoading(false)
        reloadTable()
      })
      .catch(err => {
        setIsLoading(false)
        CommonNotify('Not able to Delete Lead')
      })
  }

  const saveTags = () => {
    const URL = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/leads/add-tags`

    const pTags = tags.map(tag => {
      return tag.tag
    })

    let str = data.id
    if (str[0] == '#') {
      str = str.substring(1)
    }

    const postData = {
      tags: pTags,
      lead_id: str
    }

    axios
      .post(URL, postData)
      .then(res => {})
      .catch(err => {
        CommonNotify('Not able to set tags')
      })
  }

  const fetchTag = id => {
    if (data.tags !== null) {
      if (data.tags) {
        if (!isArray(data.tags)) {
          data.tags = JSON.parse(data.tags)
        }

        if (data.tags.length) {
          fetchTags(data.tags)
          return
        }
      }
    }
  }

  const fetchTags = tags => {
    let tgs = [...tags]
    const tg = tgs

    tg.forEach((item, index) => {
      if (!getTag(item)) {
        tgs.splice(index, 1)
      }
    })

    const newTags = tgs.map(item => {
      return getTag(item)
    })

    setTags(newTags)
  }

  const getTag = tag => {
    let selectedTag = -1
    allTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    if (selectedTag == -1) return false
    return allTags[selectedTag]
  }

  const removeTag = tag => {
    let selectedTag = -1
    const selTags = [...tags]
    selTags.forEach((item, index) => {
      if (item.tag.toLowerCase() === tag.toLowerCase()) {
        selectedTag = index
      }
    })
    selTags.splice(selectedTag, 1)
    setTags(selTags)
    setTagsUpdated(true)
  }

  const handelClick2 = () => {
    history.push(`/calldashboard?callId=${data.id}`)
  }

  const countTag = tag => {
    let tags = { ...tagCount }
    tags[tag] = tags[tag] + 1
    setTagCount(tags)
  }

  useEffect(() => {
    updateTagCount(tagCount)
  }, [tagCount])

  return (
    <>
      <Table.Row style={{ borderBottom: '2px solid rgba(34,36,38,.15)' }}>
        <Table.Cell onClick={handelClick2} style={{}}>
          <div onClick={handelClick2}>{data.id}</div>

          <div className="preview" style={{ margin: '12px auto' }}></div>
        </Table.Cell>
        <Table.Cell
          className="date-set-table"
          style={{ fontSize: '16px' }}
          onClick={handelClick}
        >
          <p>
            {moment
              .utc(data.created_at)
              .local()
              .startOf('seconds')
              .fromNow()}
          </p>
        </Table.Cell>
        <Table.Cell className="host-table" onClick={handelClick}>
          {' '}
          <div className="host-profile">
            <img className="ui medium circular image" src={hostImg} alt="" />
            <p>{data?.contact ? data?.contact.name : 'Contact ' + data?.id}</p>
          </div>
        </Table.Cell>
        <Table.Cell className="metting-name" onClick={handelClick}>
          <div
            className={data.first_source === 'Webpage' ? 'webpage' : 'facebook'}
          >
            {data.first_source === null ? 'Unknown' : data.first_source}
          </div>
        </Table.Cell>
        <Table.Cell className="host-table" onClick={handelClick}>
          <div className="host-profile" onClick={handleClickSideModal}>
            <Dropdown
              options={leadStage}
              placeholder="Select"
              basic
              defaultValue={data.stage ? data.stage : 'null'}
              onChange={(e, dat) => onStageValueChanged(e, dat, data.id)}
            />
          </div>
        </Table.Cell>
        <Table.Cell onClick={handelClick} className="Lead Owner">
          {data && (
            <Dropdown
              options={statusOptions}
              placeholder="Lead Status"
              defaultValue={data.status}
              onChange={(e, dat) =>
                onStatusChangeHandlerUpdate(e, dat, data.id)
              }
            />
          )}
        </Table.Cell>
        <Table.Cell onClick={handelClick} className="Lead Owne">
          {data && <>{data.duration ? data.duration + ' Sec' : '0 Sec'}</>}

          {data && (
            <>
              {data.recording_url !== null && (
                <>
                  {playingAudio != data.id && (
                    <Icon
                      style={{ marginLeft: '10px', fontSize: '1.8em' }}
                      name="video play"
                      onClick={e => playAudio(data.id, data.recording_url)}
                    />
                  )}

                  {playingAudio == data.id && (
                    <Icon
                      style={{ marginLeft: '10px', fontSize: '1.8em' }}
                      name="pause circle"
                      onClick={e => stopAudio(data.id)}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Table.Cell>
        <Popup
          trigger={
            <Table.Cell
              onClick={handelClick}
              style={{
                textTransform: 'capitalize',
                paddingLeft: 20,
                alignContent: 'center',
                alignItems: 'center'
              }}
            >
              {data?.status !== null &&
              (data?.final_status === 'failed_to_connect_agent' ||
                data.final_status === 'failed_to_connect_customer') ? (
                <Image src={MissedCall} style={{ width: 25, height: 25 }} />
              ) : null}

              {data.final_status !== null &&
              data.final_status === 'completed' ? (
                <Image src={PickedCall} style={{ width: 25, height: 25 }} />
              ) : null}

              {data?.final_status !== null &&
              (data?.final_status === 'suspended' ||
                data?.final_status === 'canceled') ? (
                <Image src={cutCall} style={{ width: 25, height: 25 }} />
              ) : null}
            </Table.Cell>
          }
          position="top center"
        >
          {data?.final_status
            ? data?.final_status.replace(/_/g, ' ')
            : 'No Status found'}
        </Popup>
        <Table.Cell onClick={handelClick}>{data.agent}</Table.Cell>{' '}
        <Table.Cell onClick={handelClick}>
          <div style={{ display: 'inline-block' }}>
            {tags?.map(dt => (
              <span
                onClick={() => removeTag(dt.tag)}
                style={{
                  backgroundColor: dt.color,
                  padding: '4px 10px',
                  color: '#fff',
                  borderRadius: '3px',
                  marginRight: '5px'
                }}
              >
                {' '}
                {dt.tag}{' '}
              </span>
            ))}

            <Popup
              className="profile_popup"
              content={<AddTagPopup tags={allTags} addTag={addTag} />}
              size="large"
              position="bottom center"
              flowing
              on="click"
              trigger={
                <div style={{ marginTop: '15px' }}>
                  {!tags.length && <a href="#"> + Add New Tag</a>}{' '}
                </div>
              }
            />
          </div>
        </Table.Cell>
        {data.location == 'zoom_meeting' ? (
          <Table.Cell className="location-table" onClick={handelClick}>
            <div className="location-img">
              <img className="ui medium circular image" src={zoomImg} alt="" />
              <p>Zoom</p>
            </div>
          </Table.Cell>
        ) : (
          ''
        )}{' '}
        {data.location == 'google_meeting' ? (
          <Table.Cell className="location-table" onClick={handelClick}>
            <div className="location-img">
              <img className="ui medium circular image" src={GoogleMeet} alt="" />
              <p>Google Meet</p>
            </div>
          </Table.Cell>
        ) : (
          ''
        )}
        {type === 'past' && (
          <Table.Cell className="date-set-table" onClick={handelClick}>
            not available
          </Table.Cell>
        )}
        {type !== 'past' && (
          <Table.Cell onClick={handelClick} className="social-icon-set">
            <div className="social-box-wrapper"></div>
          </Table.Cell>
        )}
      </Table.Row>

      <Modal
        className="local-number-modal"
        open={deleteModal}
        onClose={e => deleteModalView(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this lead ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={() => deleteModalView(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={onDeleteLeadHandler}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default MyLeadsTableContent
