import React, { Component, Fragment } from 'react'
import {  Image, Modal } from 'semantic-ui-react'
import _ from 'lodash'

import CommonInput from './CommonInput'

import CommonButtons from './CommonButtons'



import {isAdmin} from './Title'

import starterIcon from '../assets/images/startericon.svg'
import proIcon from '../assets/images/proicon.svg'
import advanceIcon from '../assets/images/advanceicon.svg'

import { cancelSubscription, getSubscription } from '../config/subscription'


import axios from 'axios'
import { CommonNotify } from './CommonNotify'
import moment from 'moment'

class CommonSubscriptionModal extends Component {
  state = {
    anumPlan: false,
    isModalOpen: false,
    promoCode: '',
    isCancelModalOpen: false,
    cardSummary: false,
    allPlans: [],
    activePlans: null,
    selectedPlan: { activeLabel: '', activePrice: 0, actualPrice: 0 },
    payCard: [],
    updateCard: false,    
    payCardDropdown: [],
    couponValidated:false,
    dataModal: {
      dataPlan: {
        planName: 'Pro',
        planPrice: '$119'
      },
      addCredits: '',
      promoCode: '',
      creditCardDetails: {
        cardName: '',
        cardNumber: '',
        validMonth: '',
        validYear: '',
        cvv: ''
      },
      creditCardDetailsErrors: {
        cardName: '',
        cardNumber: '',
        validMonth: '',
        validYear: '',
        cvv: ''
      }
    },
    subscriptionData: {
      plan_id: 8,
      apply_now: 1,
      period: 'Monthly',
      downgrading_reason: '',
      selected_plan_price: {},
      current_plan_price: 35,
      coupon: '',
      cardId: 0
    },
    newCard: false,
    applyCouponLoader: false,
    addPaymentLoader: false,
    addCardModalOpen: false,
    confirmOnOpen: false,
    selectedIndex: null
  }
  applyNowToggle = {
    callTitle: 'applyNow',
    callDesc: 'applyNow',
    callId: 'applyNow',
    callref: 'applyNow'
  }
  

  setPlanData = plans => {
    let { currentPlan } = this.props

    
    let images = [starterIcon, proIcon, advanceIcon, advanceIcon, advanceIcon]
    if (plans) {
      plans.forEach((item, index, theArray) => {       
        theArray[index].icon = images[index]
        theArray[index].yearly_total = 0
        item.yearly_price = item.yearly_price ? item.yearly_price : 0 

        if (item.yearly_price) {
          theArray[index].yearly_total = item.yearly_price * 12
        }
        if (currentPlan) {
          const UpdateCurrentPlaneName = currentPlan.toLowerCase();
          if (item.name.toLowerCase() == UpdateCurrentPlaneName) {
            theArray[index].active = true
          } else {
            theArray[index].active = false
          }
        }
      })
      const getActivePlaneData = plans.find(element => element.active) || {}      
      this.setState({ activePlans: getActivePlaneData })
      this.setState({ allPlans: plans })
    }
  }

  componentDidMount = async () => {
    const role_name = await localStorage.getItem('role_name')
    if(role_name === "Admin")
    {
    this.fetchPlans()
    this.fetchCard()
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.currentPlan, prevProps.currentPlan)) {
      this.fetchPlans()
      const subscriptionData = {
        plan_id: 8,
        period: 'Monthly',
        downgrading_reason: '',
        selected_plan_price: {},
        current_plan_price: 35,
        coupon: '',
        cardId: 0
      }
      
     
      this.setState({ cardSummary: false })
    }
  }

  fetchCard = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-customer-payment-methods`
    axios.get(url).then(res => {
      if (res) {
       
        if (res.data.data.length === 1) {
          const { subscriptionData } = this.state
          subscriptionData['cardId'] = res.data.data[0].id
          this.setState({ subscriptionData })
          this.setState({ updateCard: true })
          this.setState({ updateCardInfo: res.data.data[0] })
        }
        const updatePayCard = res.data.data.map(item => {
          return {
            key: `${item.card.expiry_month} / ${item.card.expiry_month}`,
            text: `**** ${item.card.last_four_digits}`,
            value: item.id
          }
        })
        this.setState({
          payCard: res.data.data,
          payCardDropdown: updatePayCard
        })
      }
    }).catch(function(error) {
       // console.log("error")
    })
  }

  applyCoupon = () => {}

  fetchPlans = () => {
    if(!isAdmin()) return;
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/app-sumo/plans`
    axios.get(url).then(res => {
      if (res.data.data) {
        this.setPlanData(res.data.data)
      }
    }).catch(function(error) {
      if (error.response) {
        
      
      } else if (error.request) {
        // The request was made but no response was received
        
      } else {
        // Something happened in setting up the request that triggered an Error
       
      }
    })
  }

  GetSubscribeData = async () => {
    getSubscription().then(res => {
      if (res && res.data) {
        localStorage.setItem('plan_name', res.data.data.plan_name)
        localStorage.setItem('max_users', res.data.data.max_users)   
      }
    })
  }

  executeCoupanCode = () => {
    this.setState({ applyCouponLoader: true })
    if (!this.state.promoCode) {
      CommonNotify('Please enter Code', 'warning')
      this.setState({ applyCouponLoader: false })
      return
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/app-sumo/${this.state.promoCode}`
   
    axios
      .get(url)
      .then(res => {       
        this.setState({ applyCouponLoader: false })
        if (res.data) {
          if(res.data.status !== 'invalid'){
            this.setState({ couponValidated: true })
            // this.GetSubscribeData();
          }else{
            CommonNotify('Invalid Coupon Code')
            this.setState({ couponValidated: false })
            // this.GetSubscribeData();
          }
        }
      })
      .catch(error => {
        this.setState({ couponValidated: false })
        this.setState({ applyCouponLoader: false })
        // this.GetSubscribeData();
        if (error.response) {        
          CommonNotify('Invalid Coupon Code')
          // Request made and server responded
         
        } else if (error.request) {
          // The request was made but no response was received
          
        } else {
          // Something happened in setting up the request that triggered an Error
         
        }
      })
  }

  handleGroupBtnData = (toggleBtn, id) => {
   
   
  }

  handleDataModal = (e, index, plans) => {
    this.setState({
      selectedIndex: index
    })
  }

  handleDataInput = e => {
    const { name, value } = e.target
    const { dataModal } = this.state
    dataModal[name] = value
    this.setState({ dataModal })
  }

  handleCreditCardInfo = e => {
    const { name, value } = e.target
    const { dataModal } = this.state
    dataModal.creditCardDetails[name] = value

    this.setState({ dataModal })
    const ERROR_CODE = {
      cardName: 'Card Name',
      cardNumber: 'Card Number',
      validMonth: 'Month',
      validYear: 'Year',
      cvv: 'CVV'
    }


    if (!value) {
      dataModal.creditCardDetailsErrors[
        name
      ] = `${ERROR_CODE[name]} is required`
      this.setState({ dataModal })
      return
    } else {



      dataModal.creditCardDetailsErrors[name] = ''
      this.setState({ dataModal })
    }

    if (name === 'cardNumber') {

     

      if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'validMonth') {
      if (value.toString().length > 2) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 2 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'validYear') {
      if (value.toString().length > 4) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }

    if (name === 'cvv') {
      if (value.toString().length > 4) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be less than 4 digit long.`
        this.setState({ dataModal })
      } else if (value < 0) {
        dataModal.creditCardDetailsErrors[
          name
        ] = `${ERROR_CODE[name]} must be greater than 0.`
        this.setState({ dataModal })
      } else {
        dataModal.creditCardDetailsErrors[name] = ''
        this.setState({ dataModal })
      }
    }
  }

  handleModal = () => {
    let { isModalOpen, cardSummary } = this.state

    isModalOpen = !isModalOpen
    cardSummary = false
    this.setState({ isModalOpen, cardSummary })
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false })
  }

  handleCancelModal = () => {
    let { isCancelModalOpen } = this.state

    isCancelModalOpen = !isCancelModalOpen

    this.setState({ isCancelModalOpen })
  }

  handleCloseCancelModal = () => this.setState({ isCancelModalOpen: false })

  handleCreditCard = () => {
    this.setState({ cardSummary: true })
    
  }

  checkSubscription = () => {
    
    if (!this.state.promoCode) {
      CommonNotify('Please enter Code', 'warning')
     
      return
    }
    this.setState({ applyCouponLoader2: true })
     
     
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/app-sumo/${this.state.promoCode}`
   
    axios
      .post(url,{})
      .then(res => {       
        this.setState({ applyCouponLoader2: false })
        this.GetSubscribeData();
        if (res.data) {
          if(res.data.status !== 'invalid'){
            CommonNotify('Plan upgraded', 'success')
            window.location.reload()
          }else{
            CommonNotify('Invalid Coupon Code')         
          }
        }
      })
      .catch(error => {   
        this.GetSubscribeData();
        this.setState({ applyCouponLoader2: false })         
        CommonNotify('Cant upgrade the plan invalid coupon code')        
      })
  }

  onCancel = () => {
    cancelSubscription()
      .then(res => {
        this.setState({ isCancelModalOpen: false })
      })
      .catch(err => console.error())
  }

  animate = (elem, style, unit, from, to, time, prop) => {
    if (!elem) return

    var start = new Date().getTime(),
      timer = setInterval(function() {
        var step = Math.min(1, (new Date().getTime() - start) / time)

        if (prop) {
          elem[style] = from + step * (to - from) + unit
        } else {
          elem.style[style] = from + step * (to - from) + unit
        }

        if (step === 1) {
          clearInterval(timer)
        }
      }, 25)
    if (prop) {
      elem[style] = from + unit
    } else {
      elem.style[style] = from + unit
    }
  }

  onChangeCheckBox = (e, index, checked, data) => {
    if (index.checked) {
      const { subscriptionData } = this.state
      subscriptionData['cardId'] = data.id
      this.setState({ subscriptionData })
    } else {
      const { subscriptionData } = this.state
      subscriptionData['cardId'] = ''
      this.setState({ subscriptionData })
    }
  }

  onAddCreditCard = () => {

    if(!this.state.dataModal.creditCardDetails.cardNumber.match(/^[-_ 0-9]+$/)){
      CommonNotify("Invalid Card No", "warning")
      return;
    }

    this.setState({ addCreditCardLoader: true })

   

    if (
      !this.state.dataModal.creditCardDetails.cardName ||
      !this.state.dataModal.creditCardDetails.cardNumber ||
      !this.state.dataModal.creditCardDetails.validMonth ||
      !this.state.dataModal.creditCardDetails.validYear ||
      !this.state.dataModal.creditCardDetails.cvv
    ) {
      CommonNotify('Please add all felids', 'warning')
      this.setState({ addCreditCardLoader: false })
      return
    }
    const date = new Date()
    const newYear = moment(date).format('yyyy')
    if (this.state.dataModal.creditCardDetails.validMonth > 12) {
      this.setState({ addCreditCardLoader: false })
      CommonNotify('Please enter valid expire date', 'warning')
      return
    } else {
      if (this.state.dataModal.creditCardDetails.validYear < newYear) {
        this.setState({ addCreditCardLoader: false })
        CommonNotify(
          'Expiry date should not be lesser then current date',
          'warning'
        )
        return
      }
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/create-customer-credit-card`

   

    const data = {
      name: this.state.dataModal.creditCardDetails.cardName,
      card_number: this.state.dataModal.creditCardDetails.cardNumber,
      expiry: `${this.state.dataModal.creditCardDetails.validMonth}/${this.state.dataModal.creditCardDetails.validYear}`,
      cvv: this.state.dataModal.creditCardDetails.cvv
    }
    axios
      .post(url, data)
      .then(res => {
        this.setState({ addCreditCardLoader: false, newCard: false })
        CommonNotify(`Card successfully ${ this.state.updateCard ? 'updated' : 'created'}`, 'success')
        this.setState({ addCardModalOpen: false })
        this.fetchCard()
      })
      .catch(err => {
        const errors = { ...err }
        if (errors.response.data.errors.length) {
          CommonNotify(errors.response.data.errors[0])
          this.setState({ addCreditCardLoader: false })
        }
      })
  }

  addPayment = () => {
    this.setState({ addPaymentLoader: true })
    if (!this.state.subscriptionData.cardId) {
      CommonNotify('Please select payment method', 'warning')
      this.setState({ addPaymentLoader: false })
      return
    }
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/update-primary-payment-method`
    const data = {
      payment_id: this.state.subscriptionData.cardId,
      plan_price:
        this.state.subscriptionData.period === 'Monthly'
          ? this.state.subscriptionData.selected_plan_price.price
          : this.state.subscriptionData.selected_plan_price.yearly_price * 12
    }
    axios
      .post(url, data)
      .then(res => {
        this.addNewSubscription()
      })
      .catch(err => {
        this.setState({ addPaymentLoader: false })
      })
  }

  addNewSubscription = () => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/subscription/update-subscription-plan`
    const data = {
      plan_id: this.state.subscriptionData.plan_id,
      apply_now: 1,
      period:
        this.state.subscriptionData.period === 'Monthly' ? 'month' : 'year',
      downgrading_reason: '',
      selected_plan_price:
        this.state.subscriptionData.period === 'Monthly'
          ? this.state.subscriptionData.selected_plan_price.price
          : this.state.subscriptionData.selected_plan_price.yearly_price,
      current_plan_price: 35,
      coupon: ''
    }
    axios
      .post(url, data)
      .then(res => {
        this.setState({ addPaymentLoader: false })
        CommonNotify('Successfully', 'success')
        this.props.updateGetSubscription()
        this.props.onClose()
      })
      .catch(err => {
        this.setState({ addPaymentLoader: false })
        const errors = { ...err }
        if (errors.response.data.errors) {
          CommonNotify(errors.response.data.errors[0])
        } else {
          CommonNotify('Some thing went wrong')
        }
      })
  }

  handleToggleData = toggleData => {
    const dataToggled = toggleData ? 1 : 0
    const { subscriptionData } = this.state
    subscriptionData['apply_now'] = dataToggled
    this.setState({ subscriptionData })
  }

  onChangePaymentDropDown = (e, data) => {
    const { subscriptionData } = this.state
    subscriptionData['cardId'] = data.value
    this.setState({ subscriptionData })
  }

  onOpenAddCardModal = () => {
    this.setState({ addCardModalOpen: true })
  }

  onCloseAddCardModal = () => {
    this.setState({ addCardModalOpen: false })
  }

  onCloseUpgradePlanModal = () => {
    this.setState({ confirmOnOpen: false })
  }

  render() {
    const {
      isModalOpen,
      isCancelModalOpen,
      dataModal,
      cardSummary
    } = this.state
    const { dataTable, currentPlan } = this.props
    const {
      cardName,
      cardNumber,
      validMonth,
      validYear,
      cvv
    } = this.state.dataModal.creditCardDetailsErrors
    return (
      <Fragment>
        <Modal
           closeOnEscape={this.props.fixed === true ? true : false} 
           closeOnDimmerClick={this.props.fixed === true ? true : false} 
          className="subscription-modal"
          open={this.props.open}
          onClose={this.props.onClose}
        >
          <Modal.Header>
            <p className="modal-header">{this.props.title}</p>
            { this.props.fixed ? null : ( <i
              onClick={this.props.onClose}
              className="fa fa-times"
              aria-hidden="true"
            ></i>) }
          </Modal.Header>
          <Modal.Content scrolling>
            <div className="plans">
              <div className="plan-list">
              {this.state.allPlans.map((plan, index) => (
                  <div className="plan-item" key="plan-item">
                   
                      <div
                        className="event-handler"
                        onClick={e => this.handleDataModal(e, index, plan)}
                        disable={false}
                      ></div>
                  

                    <Image src={starterIcon} size="small" />
                    <p className="plan-title">{plan.name}</p>
                    <p className="plan-price"></p>
                    <p className="plan-desc">
                      {"Up to " + plan.max_calls + " calls per month"}
                    </p>
                    <p className="plan-subdesc">
                      {"Up to " + plan.max_sms + " SMS per month" }
                    </p>                   
                   
                    {plan.active ? (
                      <p className="plan-status">Current Plan</p>
                    ) : null}                                    
                     
                  </div>
              )) }
                  
               
              </div>
            </div>
            <div className="holder-small-content">
              {!cardSummary && (
                <div className="summary">                
                                  
                 
                  <div className="summary-code">
                    <p className="summary-code-text">
                      <i className="fas fa-question-circle" />
                      apply AppSumo Code
                    </p>
                    <CommonInput
                      onChange={e =>
                        this.setState({ promoCode: e.target.value })
                      }
                      name="promoCode"
                      placeholder="Enter your Code here"
                    />
                    <CommonButtons
                      onClick={this.executeCoupanCode}
                      content="Apply"
                      background="blue"
                      loading={this.state.applyCouponLoader}
                    />
                  </div>             
               
                  


                  <div className="holder-credit-card">
                      { this.state.couponValidated && (
                          <CommonButtons
                          onClick={this.checkSubscription}
                          content='Upgrade Subscription'                         
                          loading={this.state.applyCouponLoader2}
                          background="blue"
                        />
                      )  }
                    
                  </div>
                
                </div>
              )}
            
            </div>
          </Modal.Content>
        </Modal>
      </Fragment>
    )
  }
}

export default CommonSubscriptionModal
