import React, { Fragment, useState } from 'react'
import { Table, Modal } from 'semantic-ui-react'

import CommonButtons from './CommonButtons'
import CommonInput from './CommonInput'
import { CommonNotify } from './CommonNotify'

import axios from 'axios'

const plan_name = localStorage.getItem('plan_name')

const CommonTableNo = props => {
  const { deleteHandle, style, handleBuy, noRecord } = props
  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [selecctedNo, setSelectedNo] = useState('')
  const [selecctedPrice, setSelectedPrice] = useState('monthly')
  const [selectedPlan, setSelectedPlan] = useState('monthly')
  const [cardNo, setCardNo] = useState('')
  const [friendlyName, setFriendlyName] = useState('')

  const cancelModalOpen = (number, price, plan = 'monthly') => {
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/credit-cards`

    axios
      .get(url)
      .then(res => {
        if (res.data.data.length) {
          res.data.data.map(row => {
            if (row.is_primary == 'yes') {
              setCardNo(row.card.last_four_digits)
              setCancelModalOpen(true)
              setSelectedNo(number)
              setSelectedPrice(price)
              setSelectedPlan(plan)
            }
          })
        } else {
          CommonNotify('Please Add a credit Card', 'warning')
        }
      })
      .catch(error => {
        // console.log("Credit Card not available / Cant Fetch Credit Cards")
        // CommonNotify(
        //   'Credit Card not available / Cant Fetch Credit Cards',
        //   'warning'
        // )//
      })
  }

  const handleThis = async data => {
    const plan_name = await localStorage.getItem('plan_name')
    data.plan = data.plan ? data.plan : plan_name
    setCancelModalOpen(false)
    setFriendlyName('')
    handleBuy(data)
  }

  return (
    <div className="common-table-wrapper">
      <Table style={style} singleLine>
        <Table.Header className="top-table-header">
          <Table.Row>
            {props.dataTable.header.map((data, i) => {
              return (
                <Table.HeaderCell
                  key={i}
                  className="default-text header-modal-style"
                  style={{ fontSize: '16px', fontWeight: 600 }}
                >
                  {data.headerTitle}
                </Table.HeaderCell>
              )
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Fragment>
            {props.dataTable.tableContentData.map((data, i) => {
              return (
                <Table.Row
                  key={i}
                  className="table-content-row"
                  style={{ fontSize: '14px' }}
                >
                  <Table.Cell>{data.phone}</Table.Cell>
                  <Table.Cell>Yes</Table.Cell>
                  <Table.Cell>Yes</Table.Cell>
                  <Table.Cell>
                    {data?.kyc
                      ? 'Business ID and Local Address proof required'
                      : 'Not required'}
                  </Table.Cell>
                  <Table.Cell>{data.price} $ / Month</Table.Cell>
                  <Table.Cell>
                    {' '}
                    <CommonButtons
                      type="button"
                      content="+Add"
                      background="blue"
                      onClick={e => cancelModalOpen(data.phone, data.price)}
                    />
                  </Table.Cell>
                </Table.Row>
              )
            })}
            {noRecord && !props.dataTable.tableContentData?.length && (
              <Fragment>
                <Table.Row
                
                  className="table-content-row"
                  style={{ fontSize: '14px' }}
                >
                  <Table.Cell>
                    {' '}
                    Sorry, there are no numbers matching your search criteria
                  </Table.Cell>
                </Table.Row>
              </Fragment>
            )}
          </Fragment>
        </Table.Body>
      </Table>

      <Modal
        className="local-number-modal"
        open={isCancelModalOpen}
        onClose={e => setCancelModalOpen(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to add {selecctedNo} ? <br />
              Total cost $ {selecctedPrice} Per Month <br />
              You will be charged from Card {cardNo}
            </p>

            <div style={{ padding: '10px 25px 35px' }}>
              <CommonInput
                placeholder="Friendly Name for Number"
                name="call-me-now"
                type="text"
                style={{ minWidth: 200 }}
                value={friendlyName}
                required={true}
                onChange={e => setFriendlyName(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setCancelModalOpen(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={e =>
                  handleThis({
                    number: selecctedNo,
                    price: selecctedPrice,
                    name: friendlyName,
                    plan: plan_name
                  })
                }
                type="button"
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
                disabled={friendlyName === ''}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default CommonTableNo
