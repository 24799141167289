import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Dropdown } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import Axios from 'axios'
//redux
import { connect } from 'react-redux'
import { getNumbers } from '../../actions/numbers'

import CommonCheckbox from '../../common/CommonCheckbox'
import CommonButton from '../../common/CommonButtons'
import NounCall from '../../assets/images/noun-call.svg'
import noRecord from '../../assets/images/no-mute.png'
import record from '../../assets/images/mute.png'
import Modal from '../../common/ConfirmModal'

import axios from 'axios'
import {
  Dimmer,
  Loader,
  Image,
  Segment,
  Confirm,
  Item
} from 'semantic-ui-react'

import { CommonNotify } from '../../common/CommonNotify'
import callicons from '../../assets/images/call_icons.png'
// import emailicon from '../../assets/images/mail_icons.png'
import recordIcon from '../../assets/images/record-check.png'
import smsIcon from '../../assets/images/sms-capabality.png'
import mmsIcon from '../../assets/images/mms-capabality.png'
import faxIcon from '../../assets/images/fax-capabality.png'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

const apiToken = localStorage.getItem('access_token')

const MyNumbers = ({ loading, usernumbers, widget, getNumbers, numbers }) => {
  const history = useHistory()
  const [myNumbers, setNumbers] = useState([])
  const [confirmDel, setConfirmDel] = useState(false)
  const [confirmDel2, setConfirmDel2] = useState(false)
  const [selectedNumber, setSelectedNumber] = useState(null)
  const [allMark, setAllMark] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const planStatus=localStorage?.getItem('account_status')

  useEffect(() => {
    setLoading(true)
    loading(true)
    fetchNumbers()
  }, [])

  useEffect(() => {
    if (numbers) {
      setLoading(false)
      loading(false)
      let tempRows = []
      if (numbers.data) {
        let tempData = [...numbers.data]
        tempData.map((row, index) => {
          if (!row.primary) {
            let tempObject = {
              ...row,
              isChecked: false,
              value: row.id,
              assigned: !row.assigned ? 'Not Assigned' : row.assigned.name,
              label: row.friendly_name
            }
            tempRows.push(tempObject)
          }
        })
      }
      setNumbers(tempRows)
      localStorage.setItem('phone_numbers', JSON.stringify(tempRows))
    }
  }, [numbers])

  const deleteNumber = number => {
    setLoading(true)
    loading(true)
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${number}`
    const data = {
      number_id: number
    }

    axios
      .delete(url)
      .then(res => {
        setLoading(false)
        loading(false)
        if (res.data.message[0]) {
          CommonNotify('Number deleted successfully', 'success')
          fetchNumbers()
        }
      })
      .catch(error => {
        setLoading(false)
        loading(false)
        CommonNotify('Cant Delete number')
      })
  }

  const getOwners = owners => {
    const res = owners.map(item => item.name)
    return res.join(', ')
  }

  const fetchNumbers = () => {
    setLoading(true)
    loading(true)
    getNumbers()
    /* const id = parseInt(localStorage.getItem('id'));
    
    const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/get-user-numbers`

    setLoading(true)
    loading(true)

    axios
      .get(url)
      .then(res => {
        setLoading(false)
        loading(false)
        if (res.data.data) {          
          let tempRows = []
          res.data.data.map((row, index) => {
            if (!row.primary) {
              row.isChecked = false;
              row.value = row.id;
              row.assigned = row.assigned === null ? "Not Assigned" : row.assigned.name
              row.label = row.friendly_name
              tempRows.push(row)
            }
          })
          setNumbers(tempRows)
          localStorage.setItem('phone_numbers',JSON.stringify(tempRows))
        }
      })
      .catch(error => {
        setLoading(false)
        loading(false)
      }) */
  }

  const deleteModal = data => {
    setSelectedNumber(data)
    setConfirmDel(true)
  }

  const handleDelete = () => {
    const val = selectedNumber.id
    const number = selectedNumber.phone_number
    setConfirmDel(false)
    setConfirmDel2(true)
    if (!val) return
    deleteNumber(val)
  }

  const onMarkAllCheckbox = (e, val, key, ch) => {
    const data = [...myNumbers]
    data.forEach(function(d) {
      d.isChecked = !key
    })
    setAllMark(!allMark)
    setNumbers(data)
  }

  const onChangeSelect = (e, val, key, ch, id) => {
    const data = [...myNumbers]

    data[id].isChecked = !data[id].isChecked
    setNumbers(data)
  }

  const OnEditNumber = id => {
    history.push(`/numbers/${id}`)
  }

  const close = async () => {
    setConfirmDel(false)
  }

  const close2 = async () => {
    setConfirmDel2(false)
  }

  /*********************** On Bulk Delete ****************** */

  const onBulkDelete = async () => {
    const data = [...myNumbers]
    var selectedNumbers = []
    data.forEach(function(d) {
      if (d.isChecked === true) {
        selectedNumbers.push(d.id)
      }
    })

    if (selectedNumbers.length === 0) {
      CommonNotify('Please select number first.')
    } else {
      setLoading(true)
      loading(true)

      selectedNumbers.forEach(async id => {
        const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/number/${id}`
        await axios.delete(url)
      })
      fetchNumbers()
      setLoading(false)
      loading(false)
      CommonNotify('Number deleted successfully', 'success')
    }
  }

  return (
    <>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <div className="my-number-tab manage_table">
        <div className="table-heading-wrapper">
          <Dropdown text="Actions">
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => onBulkDelete()} text="Delete" />
            </Dropdown.Menu>
          </Dropdown>
          
          <CommonButton onClick={() => planStatus === 'Active' ? history.push("/addNumbers") : CommonNotify('Please subscribe to a paid plan to order a new number')} content="Add Number" btnClass="btn-blue" />
         
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <CommonCheckbox
                  onChange={(e, val, key, ch) =>
                    onMarkAllCheckbox(e, val, key, ch)
                  }
                  name="isMarkAllCheckbox"
                  checked={allMark}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Phone Number</Table.HeaderCell>
              <Table.HeaderCell>Capability</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Recording</Table.HeaderCell>
              <Table.HeaderCell>Forwarded to</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {myNumbers.map((row, index) => (
              <Table.Row>
                <Table.Cell>
                  <CommonCheckbox
                    onChange={(e, val, key, ch) =>
                      onChangeSelect(e, val, key, ch, index)
                    }
                    name="isChecked"
                    checked={row.isChecked}
                  />
                </Table.Cell>
                <Table.Cell>
                  <>
                    <p style={{ marginRigth: '10px' }}>{row?.label}</p>
                    <p style={{ marginRigth: '10px' }}>
                      {row.phone_number}
                    </p>{' '}
                    <p style={{ marginLeft: '6px', marginRight: '10px' }}>
                      {' '}
                      {row.country}{' '}
                      <span style={{ marginLeft: '10px' }}>
                        <img
                          src={`https://flagcdn.com/16x12/${row?.country?.toLowerCase()}.png`}
                        />{' '}
                      </span>{' '}
                    </p>
                  </>
                  <p
                    style={{
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      display: 'none'
                    }}
                  >
                    <i
                      style={{
                        fontSize: '20px',
                        color: '#737373',
                        marginRight: '5px'
                      }}
                      className="fa fa-phone"
                    ></i>{' '}
                    <i
                      style={{ fontSize: '20px', color: '#737373' }}
                      className="fa fa-comment"
                    ></i>
                  </p>
                </Table.Cell>
                {/* <Table.Cell>
                <p>{row.price} $</p>
              </Table.Cell> */}
                {/* <Table.Cell>
                <p>{getOwners(row.shared_with)}</p>
              </Table.Cell> */}
                <Table.Cell>
                  <p className="image-sizes">
                    {row?.capabilities?.sms && (
                      <img
                        style={{
                          padding: '2px',
                          marginRight: '10px'
                        }}
                        src={smsIcon}
                      />
                    )}
                    {row?.capabilities?.mms && (
                      <img
                        style={{
                          padding: '2px',
                          marginRight: '10px'
                        }}
                        src={mmsIcon}
                      />
                    )}
                    {row?.capabilities?.fax && (
                      <img
                        style={{
                          padding: '2px',
                          marginRight: '10px'
                        }}
                        src={faxIcon}
                      />
                    )}
                    {row?.capabilities?.voice && (
                   <img
                   style={{
                     // borderRadius: '30px',
                     // backgroundColor: 'yellowgreen',
                     padding: '3px',
                     marginRight: '10px',
                     height: 35,
                     width: 35,
                   }}
                   src={NounCall}
                 />
                    )}
                  </p>
                </Table.Cell>
                <Table.Cell>
                  <p>{+row?.status === 0 ? 'Inactive' : 'Active'}</p>
                </Table.Cell>

                <Table.Cell>
                  <p className="ml-4">
                  <img
                        style={{
                          // backgroundColor: 'yellowgreen',
                          padding: '3px',
                          height: 40,
                          width: 40,
                          marginTop: '-4px'
                        }}
                        src={+row?.configuration?.record_calls != 0 ? record: noRecord}
                      />
                    {/* {+row?.configuration?.record_calls != 0 ? (
                      <img
                        style={{
                          backgroundColor: 'yellowgreen',
                          padding: '3px',
                          height: '30px',
                          width: '30px'
                        }}
                        src={recordIcon}
                      />
                    ) : (
                      <HighlightOffIcon
                        style={{
                          backgroundColor: 'yellowgreen',
                          padding: '3px',
                          height: '30px',
                          width: '30px'
                        }}
                      />
                    )} */}
                  </p>
                </Table.Cell>

                <Table.Cell>
                  <p>{row.assigned}</p>
                </Table.Cell>
                <Table.Cell>
                  <div className="number_button">
                    <CommonButton
                      type="button"
                      content="Manage"
                      background="blue"
                      onClick={e => OnEditNumber(row.id)}
                    />

                    <CommonButton
                      type="button"
                      content="Cancel"
                      background="red"
                      onClick={e => deleteModal(row)}
                      style={{ borderRadius: 0, marginLeft: '10px' }}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Confirm
          className="confirmBox-wrapper"
          open={confirmDel}
          header="Delete Number!"
          content={`Do you want to delete Number :  ${
            selectedNumber !== null ? selectedNumber.phone_number : ''
          } ?`}
          onCancel={close}
          onConfirm={handleDelete}
        />

        <Confirm
          className="confirmBox-wrapper"
          open={confirmDel2}
          header={`Number Unsubcribed`}
          content={`the selected number will be cancelled at the end of subscription and remove it at end of subscription at ${selectedNumber?.next_billing_at}`}
          onConfirm={close2}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  numbers: state.numbers.numbers
})

export default connect(mapStateToProps, { getNumbers })(MyNumbers)
