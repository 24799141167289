import { borderRight } from '@mui/system'
import Axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  Button,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Modal,
  TextArea
} from 'semantic-ui-react'
import searchIcon from '../../../src/assets/images/search-icon.svg'
import CommonButtons from '../../common/CommonButtons'
import { CommonNotify } from '../../common/CommonNotify'
import { Typography } from '@mui/material'
import quickTips1 from '../../assets/images/quick-tips-1.png'
import quickTips2 from '../../assets/images/quick-tips-2.png'
import quickTips3 from '../../assets/images/quick-tips-3.png'


const intitialValue = {
  type: 'message',
  target: 'Lead',
  title: '',
  description: ''
}
export default function QuickReplies() {
  const [availableTemplateList, setAvailableTemplateList] = useState([])
  const [availableTemplateListAll, setAvailableTemplateListAll] = useState([])
  const [loading, setLoading] = useState(false)
  const [newTemplates, setNewTemplates] = useState(false)
  const [selectedTemplated, setSelectedTemplated] = useState(null)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [searchValue, setSarchValue] = useState(null)
  const [personalise, setPersonalise] = useState(false)

  useEffect(() => {
    if (searchValue) {
      filterRecord()
    } else {
      setAvailableTemplateList(availableTemplateListAll || [])
      setSelectedTemplated(
        availableTemplateListAll?.[0] && availableTemplateListAll?.[0]
      )
    }
  }, [searchValue])

  useEffect(() => {
    if (newTemplates) {
      setSelectedTemplated({ ...intitialValue })
    }
  }, [newTemplates])

  useEffect(() => {
    handleGetTemplate()
  }, [])

  const filterRecord = () => {
    const filterValue = availableTemplateListAll?.filter(
      ({ title }) =>
        title &&
        title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    )
    setSelectedTemplated(filterValue?.[0] && filterValue?.[0])
    setAvailableTemplateList(filterValue || [])
  }

  const handleGetTemplate = async flag => {
    setLoading(true)
    let data = []
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Lead`
    await Axios.get(url)
      .then(res => {
        const filter = res?.data?.data?.map(list => {
          list.target = 'Lead'
          return list
        })
        data = [...data, ...filter]
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors[0] || 'Somethign went to wrong!'
        )
      })
    url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-responses?target=Conversation`
    await Axios.get(url)
      .then(res => {
        const filter = res?.data?.data?.map(list => {
          list.target = 'Conversation'
          return list
        })
        data = [...data, ...filter]
        if (flag !== true) {
          setSelectedTemplated((data?.[0] && data?.[0]) || null)
        } else {
          if (!selectedTemplated?.id) {
            const selectRecord = data?.filter(
              ({ title, description }) =>
                title === selectedTemplated?.title &&
                description === selectedTemplated?.description
            )
            if (selectRecord && selectRecord?.length && selectRecord?.[0]) {
              setSelectedTemplated(selectRecord[0])
            }
          }
        }
        setAvailableTemplateList(data || [])
        setAvailableTemplateListAll(data || [])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors[0] || 'Somethign went to wrong!'
        )
      })
  }

  const handleDeleteTemplate = async () => {
    setOpenConfirmationModal(false)
    if (selectedTemplated?.id) {
      setLoading(true)
      const url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-response/${selectedTemplated?.id}`
      await Axios.delete(url)
        .then(res => {
          handleGetTemplate()
          CommonNotify('Deleted successfully')
        })
        .catch(err => {
          setLoading(false)
          CommonNotify(
            err?.response?.data?.errors[0] || 'Somethign went to wrong!'
          )
        })
    } else {
      CommonNotify('Please select any template first')
    }
  }

  const handleSaveTemplate = async () => {
    let url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-response`,
      payload = {
        type: 'message',
        target: '',
        title: '',
        description: ''
      }
    if (
      !selectedTemplated?.title ||
      !selectedTemplated?.target ||
      !selectedTemplated?.description
    ) {
      CommonNotify('Please fill all filed, All field are required')
      return
    }
    setLoading(true)
    payload.title = selectedTemplated?.title
    payload.target = selectedTemplated?.target
    payload.description = selectedTemplated?.description
    if (selectedTemplated?.id) {
      url = `${process.env.REACT_APP_BASE_APP_URL}/api/v1/user/quick-response/${selectedTemplated?.id}/update`
    }
    await Axios.post(url, payload)
      .then(res => {
        CommonNotify(
          selectedTemplated?.id
            ? 'Updated successfully'
            : 'Created successfully'
        )
        setNewTemplates(false)
        handleGetTemplate(true)
      })
      .catch(err => {
        setLoading(false)
        CommonNotify(
          err?.response?.data?.errors[0] || 'Somethign went to wrong!'
        )
      })
  }

  const handleSelectTemplates = list => {
    setSelectedTemplated(list)
    setNewTemplates(false)
  }

  const handleSearch = (e, data) => {
    setSarchValue(e?.target?.value)
  }

  const handleChange = (name, value) => {
    setSelectedTemplated({
      ...selectedTemplated,
      [name]: value
    })
  }

  const handleChangeDropdown = async ({ value }) => {
    handleChange('target', value)
    return
  }

  const handlePersonalise = () => {
    setPersonalise(!personalise)
  }

  const handleAppendMessage = value => {
    setSelectedTemplated({
      ...selectedTemplated,
      ['description']: `${
        selectedTemplated?.description ? selectedTemplated?.description : ''
      } ${value}`
    })
  }

  return (
    <div className="quick-replies">
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
      {/* Top header Part */}
      <div className="mt-5">
        <div className="main-title">
          <div>
            <h3>Quick Replies</h3>
            <span>Learn More</span>
          </div>
          <Button
            className="btn create-template-button"
            onClick={() => setNewTemplates(!newTemplates)}
          >
            + Create Template
          </Button>
        </div>
      </div>

      <h4>How does it work?</h4>
      <div className='quick-tips'>
      <div className='quick-child'>
        <img src={quickTips1} alt="quick1" />
        <Typography>Find out how to cerate and use canned responses</Typography></div>
      <div className='quick-child'>
      <img src={quickTips2} alt="quick2" />
        
        <Typography>Read more about private canned responses taht only you can use</Typography></div>
      <div className='quick-child'>
      <img src={quickTips3} alt="quick3" />
        
        <Typography>Learn how to get response suggestions while chatting</Typography></div>
      </div>
      {/* Second Part */}
      <div className="row">
        <div className="col-md-4">
          <div className="template-box-wrap">
            <div className="search-field">
              <Input
                placeholder="Start typing to search..."
                onChange={(e, data) => handleSearch(e, data)}
                value={searchValue}
              />
              <div className="search-icon">
                <img src={searchIcon} alt="search" />
              </div>
            </div>
            <ul className="template-lists">
              {availableTemplateList &&
                availableTemplateList?.length > 0 &&
                availableTemplateList?.map((list, i) => {
                  return (
                    <li
                      className={
                        selectedTemplated?.id === list?.id ? 'active cursor-pointer' : 'cursor-pointer'
                      }
                      key={i}
                      onClick={() => handleSelectTemplates(list)}
                    >
                      <span title={list?.title} className="reply">
                        {list?.title}{' '}
                      </span>
                      <span className="labelStyle">{list?.target}</span>
                      <span className="reply-time">
                        {list?.updated_at
                          ? moment(list?.updated_at).format('DD-MM-YY,h:mm a')
                          : ''}{' '}
                      </span>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
        <div className="col-md-8">
          <div className="template-box-right-wrap">
            <div className="form-field">
              <label>Template Name</label>
              <Input
                className="width-input"
                value={selectedTemplated?.title || ''}
                onChange={e => {
                  handleChange('title', e?.target?.value)
                }}
              />
            </div>
            <div className="form-field">
              <label>Type</label>
              <div className="col-lg-7 px-0">
                <Dropdown
                  placeholder={'Select group...'}
                  fluid
                  search
                  selection
                  name="target"
                  onChange={(e, result) => handleChangeDropdown(result)}
                  value={selectedTemplated?.target || ''}
                  options={[
                    { text: 'Lead', key: 1, value: 'Lead' },
                    { text: 'Conversation', key: 2, value: 'Conversation' }
                  ]}
                />
              </div>
            </div>
            <div className="form-field">
              <label>Template Message</label>
              <p>
                Add variables to the message that get dynamically filled based
                on the customer in the ticket and the support agent responding
                to the ticket.
              </p>
              <div className="col-lg-12 px-0">
                <Row>
                  <Col md={7} className="pr-0">
                    <TextArea
                      fluid
                      rows={10}
                      className="d-block"
                      value={selectedTemplated?.description}
                      onChange={e => {
                        handleChange('description', e?.target?.value)
                      }}
                    />
                  </Col>
                  <Col md={3}>
                    <ul className="msgOpts">
                      <li
                        className={`${personalise ? 'show' : ''}`}
                        onClick={() => handlePersonalise()}
                      >
                        <span>Personalise</span>

                        <div className="personalise-tags">
                          <span onClick={() => handleAppendMessage(`{NAME}`)}>
                            Name
                          </span>
                          <span onClick={() => handleAppendMessage(`{EMAIL}`)}>
                            Email
                          </span>
                          <span
                            onClick={() =>
                              handleAppendMessage(`{COMPANY_NAME}`)
                            }
                          >
                            Company Name
                          </span>
                          <span
                            onClick={() =>
                              handleAppendMessage(`{MEETING_LINK}`)
                            }
                          >
                            Meeting Link
                          </span>
                        </div>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
            {/* <p>
              Image attachments in Fast reply templates are supported only on
              Whatsapp & Instagram DM channels. You can attach a maximum of 1
              image
            </p> */}
            <div className="button_container">
              <div>
                <Button
                  className="btn save-button mr-3"
                  onClick={handleSaveTemplate}
                >
                  Save Template
                </Button>
                {/* <Button
                  className="btn duplicate-button"
                  disabled={newTemplates}
                >
                  Duplicate Template
                </Button> */}
              </div>
              <Button
                className="btn delete-button"
                disabled={newTemplates}
                onClick={() => setOpenConfirmationModal(true)}
              >
                Delete Template
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="local-number-modal"
        open={openConfirmationModal}
        onClose={e => setOpenConfirmationModal(false)}
      >
        <Modal.Content>
          <div className="modal-content">
            <p style={{ marginTop: 20 }}>
              Are you sure you want to delete this template ?
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(0, 0, 0, 0.05)',
                paddingTop: 10,
                paddingBottom: 10
              }}
            >
              <CommonButtons
                onClick={e => setOpenConfirmationModal(false)}
                type="button"
                content="Cancel"
                background="red"
                style={{
                  borderRadius: 0,
                  backgroundColor: '#e0e1e2',
                  color: '#5a5a5a'
                }}
              />

              <CommonButtons
                onClick={handleDeleteTemplate}
                type="button"
                content="Confirm"
                background="blue"
                style={{
                  marginLeft: 20,
                  marginRight: 20,
                  borderRadius: 0,
                  backgroundColor: '#1071eb',
                  color: '#ffffff'
                }}
              />
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}
